
// import { getRecommendItemList } from '@/api/client-api/goods/shopindex.js';
import {
  getTradePayChannel,
  getRecommendItemList,
} from '@/api/client-api/goods/pay.js';
// import payBottom from '@/components/paybottom.vue';
import payBottom from '@/components/indexbottom.vue';
import { mapGetters } from 'vuex';
import { getActivityImgData } from '@/api/client-api/activity/activity';
export default {
  components: {
    payBottom,
  },
  data() {
    return {
      totalMoney: '',
      currencyFont: '', // 币种文字
      RecommendItemList: [], // 智能推荐
      tid: '', // 订单号
      supplier_name: '', // 商家名称
      payment_name: '', // 支付方式
      supplier_id: '',
      // userdetail: {},
      hasMultiple: false, // 是否是批量支付订单
      goodsId: '', // 商品id
      goodsPic: '', // 商品图片
      activityImgInfo: {}, // 营销活动图片信息
    };
  },
  mounted() {
    this.tid = this.$route.query.tid;

    if (this.tid?.split(',').length > 1) {
      this.hasMultiple = true;
    }
    this.getRecommendItemList();
    this.getTradePayChannel(this.tid);
    // this.userdetail = JSON.parse(this.$store.state.login.loginInfo.userdetail);
    this.getActivityImgInfo(); // 获取下单成功页面 营销活动图片信息
  },
  computed: {
    ...mapGetters({
      currLang: 'common/currLang',
    }),
  },
  methods: {
    async getTradePayChannel(id) {
      let option = {
        tid: id,
        MUTE_WARNING: 1,
      };
      await getTradePayChannel(option)
        .then((res) => {
          if (res.errcode == 0) {
            this.payment_name =
              res.data.payment_channel_name + res.data.card_no;
            this.totalMoney = res.data.total_fee;
            this.currencyFont = res?.data?.currency == '1' ? '元' : '美元';
            this.supplier_name = res.data.supplier_name;
            this.supplier_id = res.data.supplier_id;
            this.goodsId = res?.data?.item_id || '';
            this.goodsPic = res?.data?.image_default_id || '';
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 跳转店铺列表页
    goSupplierShop() {
      this.$router.push('/shop_' + this.supplier_id + '.html');
    },
    // 点击再下一单,跳转商品详情
    goGoodsDetail() {
      this.toCachedShopdetail(this.goodsId, this.goodsPic, '', '_self');
    },
    // 跳转订单列表页
    shopList() {
      this.$router.push('/trade/allorder');
    },
    async getRecommendItemList() {
      let option = {
        limit: 6,
        MUTE_WARNING: 1,
      };
      await getRecommendItemList(option)
        .then((res) => {
          if (res.errcode == 0) {
            this.RecommendItemList = res.data;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    moredetail(obj) {
      this.toCachedShopdetail(
        obj.item_id,
        obj.image_default_id,
        obj.sku_lowest_price_img
      );
    },
    goindex() {
      this.$router.push('/');
    },
    // 点击营销图片
    handleClickActivityImg() {
      if (!this.activityImgInfo?.link) return;
      window.open(this.activityImgInfo.link);
    },
    // 获取底部营销图片信息
    async getActivityImgInfo() {
      let options = {
        language: this.currLang == 'en' ? '2' : '1',
        type: '8', // 图片类型：8 下单成功页面营销图
        MUTE_WARNING: 1,
      };
      const res = await getActivityImgData(options);
      if (res?.errcode == 0) {
        this.activityImgInfo = res.data?.orderImg || {};
      }
    },
  },
};
