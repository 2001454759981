
import {
  RECHARGE_DETAIL_CONFIG,
  REDUCE_DETAIL_CONFIG,
} from '../js/record_detail_config';
export default {
  name: 'RecordDetailDialog',
  props: {
    // 展示的信息
    rowInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {
    detailData() {
      // transaction_type
      if (this.rowInfo?.transaction_type == '扣款') {
        return REDUCE_DETAIL_CONFIG;
      } else if (this.rowInfo?.transaction_type == '充值') {
        return RECHARGE_DETAIL_CONFIG;
      } else return [];
    },
  },
  methods: {
    // 点击关闭弹窗
    handleCloseDialog() {
      this.$emit('close-dialog');
    },
    // 空值处理
    handleEmptyData(val) {
      return !val ? '--' : val;
    },
    // 处理图片
    handleFileData(value) {
      if (value == '--') return '';
      return Array.isArray(value) ? value?.join(',') : value;
    },
  },
  mounted() {},
};
