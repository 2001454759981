
// 组件
import ProcessStep from './components/ProcessStep.vue'; // 纠纷处理进度
import SystemDetails from './components/SystemDetails.vue'; // 纠纷详情
import Solution from './components/Solution.vue'; // 解决方案
import Evidence from './components/Evidence.vue'; // 证据
import OrderInfo from './components/OrderInfo.vue'; // 订单信息
import RefundDetail from './components/RefundDetail.vue'; // 退货政策/退货地址
import History from './components/History.vue'; // 纠纷历史

import LogisticsDialog from './components/LogisticsDialog.vue'; // 物流详情弹窗
import RefuseDialog from './components/RefuseDialog.vue'; // 拒绝方案弹窗
import ReturnGoodsDialog from './components/ReturnGoodsDialog.vue'; // 提交退货凭证弹窗
// api
import {
  getIntervention,
  getOrderTrajectory,
} from '@/api/client-api/trade/orderlist.js';
import {
  getDisputeDetail,
  responsePlatformScheme,
  refuseSupplierPlan,
  agreeSupplierPlan,
  cancelRefund,
  applyPlatform,
  submitReturnProof,
} from '@/api/client-api/trade/disputes';
export default {
  name: 'DisputesDetail',
  components: {
    ProcessStep,
    SystemDetails,
    Solution,
    Evidence,
    OrderInfo,
    RefundDetail,
    History,
    LogisticsDialog,
    RefuseDialog,
    ReturnGoodsDialog,
  },
  props: {},
  data() {
    return {
      currency: '', // 币种
      disputeNum: '', // 纠纷单号
      disputeStatus: '', // 纠纷状态
      statusStepRecord: '', // 纠纷处理进度记录
      disputeEndTime: '', // 纠纷结束时间
      disputeInfo: {}, // 纠纷详情信息
      userLogiInfo: '', // 用户物流详情

      logiDetailDialog: false, // 物流详情弹窗状态
      logisticsTrack: [], // 物流轨迹
      returnGoodsInfo: {}, // 退货凭证信息

      systemInfo: {}, // 纠纷详情信息

      newSolution: {}, // 最新方案
      finalSolution: {}, // 最终方案
      handler: '', // 处理方 1分销商 2供应商 3平台 4分销商和供应商

      evidenceInfo: {}, // 分销商&供应商证据信息
      orderInfo: {}, // 订单信息
      returnPolicy: {}, // 退货政策
      returnAddress: {}, // 退货地址
      historyInfo: [], // 纠纷历史

      shopSolution: {}, // 分销商方案
      supplierSolution: '', // 供应商方案
      platformSolution: '', // 平台方案

      refuseDialog: false, // 拒绝方案弹窗
      maxRefund: '', // 最大退款金额
      responseType: '', // 响应方案类型 supplier供应商方案 platform平台方案

      revokeDialog: false, // 撤销申请弹窗状态
      agreeDialog: false, // 同意方案二次确认弹窗状态(同意供应商/平台方案)

      returnGoodsDialog: false, // 提交退货凭证弹窗状态
      refusalRefundInfo: '', // 拒付退款原因
    };
  },
  mounted() {
    this.disputeNum = this.$route.query.item_id; // 获取纠纷单号
    this.getDisputeDetail(); // 获取纠纷详情
  },
  methods: {
    // 获取纠纷详情
    async getDisputeDetail() {
      try {
        let options = {
          aftersales_bn: this.disputeNum,
        };
        const res = await getDisputeDetail(options);
        if (res?.errcode == 0) {
          let detailInfo = res?.data?.list || '';
          this.statusStepRecord = detailInfo?.dispute_status_record || ''; //纠纷处理进度记录
          this.currency = res?.data?.currency || '￥'; // 币种
          this.maxRefund = res?.data?.maximum_refundable || '0.00'; // 最大退款金额

          this.newSolution = detailInfo?.last_info; // 最新方案
          this.finalSolution = detailInfo?.final_solution; // 最终方案
          this.handler = detailInfo?.processing_attribution; // 处理归属

          this.disputeStatus = detailInfo?.dispute_status; // 纠纷状态
          this.disputeEndTime = detailInfo?.dispute_end_time; // 纠纷结束时间
          this.userLogiInfo = detailInfo?.user_logistics_info || ''; // 用户物流详情

          this.systemInfo = {
            tid: detailInfo?.tid, // 关联订单号
            applyReason: detailInfo?.apply_reason, // 纠纷原因
            systemTip: this.finalSolution?.result, // 系统提示
            disputeStartTime: detailInfo?.acreated_time, // 纠纷发起时间
            handleDeadline: detailInfo?.handle_deadline, // 剩余处理时间
            handleCue: detailInfo?.countdown_cue, // 剩余时间展示提示
          };

          // 分销商&供应商上传证据信息
          this.evidenceInfo = {
            shop: detailInfo?.user_info?.evidence_pic,
            supplier: detailInfo?.supplier_info?.evidence_pic,
          };

          // 订单信息
          this.orderInfo = {
            tid: detailInfo?.tid, // 订单号
            totalPrice: detailInfo?.original_price, // 订单总额
            discountedPrice: detailInfo?.deduction_amount, // 优惠金额
            realPayment: detailInfo?.actual_amount, // 实付款
            rewardAmount: detailInfo?.reward_amount, // 奖励金额
            createdTime: detailInfo?.tcreated_time, // 订单创建时间
            source: detailInfo?.order_source, // 订单来源
            remark: detailInfo?.user_remarks, // 订单备注
            // 收货地址
            address:
              (detailInfo?.receiver_country || '') +
              (detailInfo?.receiver_state || '') +
              (detailInfo?.receiver_city || '') +
              (detailInfo?.receiver_county || '') +
              (detailInfo?.receiver_address || '--'),

            goodPic: detailInfo?.pic_path, // 商品主图
            goodTitle: detailInfo?.title, // 商品标题
            spec: detailInfo?.spec_nature_info, // 商品规格
            item_id: detailInfo?.item_id, // 商品id
            goodLate: detailInfo?.has_delayed_delivery, // 晚发必赔
            goodFake: detailInfo?.has_quality_product, // 假一赔三
            unitPrice: detailInfo?.supply_price_tax, // 商品单价
            number: detailInfo?.num, // 数量
            declaredValue: detailInfo?.declare_value, // 申报价值
            supplier_id: detailInfo?.supplier_id, // 供应商ID

            actual_price: detailInfo?.actual_price, //实付人民币
            currency_rate: detailInfo?.currency_rate, //当时订单的汇率
          };

          this.returnPolicy = detailInfo?.return_policy || {}; // 退货政策

          // 退货地址
          this.returnAddress = {
            person: detailInfo?.return_person, // 收件人
            phone: detailInfo?.return_tel, // 电话
            postal: detailInfo?.return_postal, // 邮编
            country: detailInfo?.return_country, // 国家
            province: detailInfo?.return_province, // 省份/地区/州
            city: detailInfo?.return_city, // 城市
            address: detailInfo?.return_address, // 退货地址
            fullAddress:
              (detailInfo?.return_country || '') +
              (detailInfo?.return_province || '') +
              (detailInfo?.return_city || '') +
              (detailInfo?.return_address || '--'),
          };
          this.historyInfo = detailInfo?.historical_info || []; // 纠纷历史

          this.shopSolution = detailInfo?.user_info || ''; //  分销商方案
          this.supplierSolution = detailInfo?.supplier_info || ''; // 供应商方案
          this.platformSolution = detailInfo?.platform_info || ''; // 平台方案

          this.returnGoodsInfo = detailInfo?.user_logistics_info; // 退货凭证信息
          this.refusalRefundInfo = detailInfo?.refusal_refund; // 拒付退款原因
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 打开物流详情弹窗
    async openLogiDialog() {
      await this.getTrajectoryInfo(); // 获取物流轨迹信息
      this.logiDetailDialog = true;
    },
    // 获取物流轨迹信息
    async getTrajectoryInfo() {
      try {
        let options = {
          logi_no: this.userLogiInfo.logistics_no,
          type: 'object',
        };
        const res = await getOrderTrajectory(options);
        if (res?.errcode == 0) {
          this.logisticsTrack = res?.data || [];
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 关闭弹窗
    closeDialog() {
      this.logiDetailDialog = false; // 关闭物流详情弹窗
      this.refuseDialog = false; // 关闭拒绝方案弹窗
      this.responseType = ''; // 重置响应方案对象

      this.revokeDialog = false; // 关闭撤销申请弹窗
      this.agreeDialog = false; // 关闭方案二次确认弹窗(同意供应商/平台方案)
      this.returnGoodsDialog = false; // 关闭提交退货凭证弹窗
    },
    /**
     * 打开拒绝方案弹窗
     * @param {String} type 对应方案所属方 supplier拒绝供应商 platform拒绝平台
     */
    openRefuseDialog(type) {
      this.responseType = type;
      this.refuseDialog = true; // 打开拒绝方案弹窗
    },
    /**
     * 提交拒绝方案信息
     * @param {Object} info 后端需要的传参，已经处理好了
     */
    async refusePlan(info) {
      let options = {
        ...info,
      };
      let res;
      if (this.responseType == 'supplier') {
        options.tid = this.orderInfo?.tid; // 订单号
        options.aftersales_bn = this.disputeNum; // 纠纷单号
        res = await refuseSupplierPlan(options);
      }
      if (this.responseType == 'platform') {
        options.id = this.newSolution?.id; // 平台方案id
        options.result = '2'; // 方案处理 1-同意 2-拒绝
        res = await responsePlatformScheme(options);
      }
      if (res?.errcode == 0) {
        if (res?.msg) this.$message.success(res?.msg);
        this.closeDialog(); // 关闭弹窗
        this.getDisputeDetail(); // 重新获取订单详情
      }
    },
    /**
     * 打开二次确认弹窗(撤销纠纷，同意供应商/平台方案)
     * @param {String} name 要打开的弹窗名
     * @param {String} type 对应方案所属方,同意方案需要 supplier供应商 platform平台
     */
    openSecondConfirm(name, type) {
      this[name] = true; // 打开对应弹窗
      if (type) this.responseType = type;
    },
    // 确认撤销纠纷申请
    async sureRevoke() {
      try {
        let options = {
          tid: this.orderInfo?.tid, // 订单号
        };
        const res = await cancelRefund(options);
        if (res?.errcode == 0) {
          if (res?.msg) this.$message.success(res?.msg);
          this.closeDialog(); // 关闭弹窗
          this.getDisputeDetail(); // 重新获取订单详情
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 确认同意方案
    async sureAgree() {
      try {
        let res;
        if (this.responseType == 'supplier') {
          let options = {
            tid: this.orderInfo?.tid, // 订单号
            aftersales_bn: this.disputeNum, // 纠纷单号
          };
          res = await agreeSupplierPlan(options);
        }
        if (this.responseType == 'platform') {
          let options = {
            id: this.newSolution?.id, // 平台方案id
            result: '1', // 方案处理 1-同意 2-拒绝
          };
          res = await responsePlatformScheme(options);
        }
        if (res?.errcode == 0) {
          if (res?.msg) this.$message.success(res?.msg);
          this.closeDialog(); // 关闭弹窗
          this.getDisputeDetail(); // 重新获取订单详情
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 申请平台介入
    async applyPlatform() {
      try {
        let options = {
          tid: this.orderInfo?.tid, // 订单号
          aftersales_bn: this.disputeNum, // 纠纷单号
        };
        const res = await applyPlatform(options);
        if (res?.errcode == 0) {
          if (res?.msg) this.$message.success(res?.msg);
          this.getDisputeDetail(); // 重新获取订单详情
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 提交退货凭证
    async submitReturnProof(info) {
      let options = {
        tid: this.orderInfo?.tid, // 订单号
        aftersales_bn: this.disputeNum, // 纠纷单号
        ...info,
      };
      const res = await submitReturnProof(options);
      if (res?.errcode == 0) {
        if (res?.msg) this.$message.success(res?.msg);
        this.closeDialog(); // 关闭弹窗
        this.getDisputeDetail(); // 重新获取订单详情
      }
    },
  },
};
