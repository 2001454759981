
// 接口
import { getPaymentChannel } from '@/api/client-api/fund/transactions.js';
// 配置
import { TRANSACTIONTYPE } from '../../js/parameterconfig';
// 工具
import dayjs from 'dayjs';
export default {
  name: 'ReportSearch',
  props: {
    // 当前激活的主标签
    tab: {
      type: Number,
      default: '',
    },
    // 当前激活的币种
    currency: {
      type: [Number, String],
      default: '1',
    },
  },
  data() {
    return {
      TRANSACTIONTYPE,
      // 支付方式
      paymentChannelOptions: [],
      // 下拉加输入的下拉列表
      keywordOptions: [
        { label: '交易号', value: 'tran_id' },
        { label: '订单号', value: 'order_id' },
      ],
      // 保证金
      depositOptions: [
        { label: '全部', value: '1' },
        { label: '运费保证金', value: '2' },
        { label: '活动保证金', value: '3' },
        { label: '分销保证金', value: '4' },
      ],
      // 筛选内容
      searchForm: {
        business_type: '', // 交易类型
        payment_channel: '', //支付方式
        create_time: [], // 交易完成时间
        keys: '', //下拉加输入框key
        value: '', //下拉加输入框value
        security_deposit: '', //保证金
      },
    };
  },
  computed: {
    // 交易类型
    classOptions() {
      if (this.tab == 1) {
        return [
          { label: '全部', value: TRANSACTIONTYPE.ALL },
          { label: '支付', value: TRANSACTIONTYPE.PAY },
          { label: '退款', value: TRANSACTIONTYPE.REFUND },
          { label: '充值', value: TRANSACTIONTYPE.RECHARGE },
          { label: '转账', value: TRANSACTIONTYPE.TRANSFORMONEY },
          { label: '扣款', value: TRANSACTIONTYPE.DEDUCTION },
          { label: '收入', value: TRANSACTIONTYPE.INCOME },
          { label: '提现', value: TRANSACTIONTYPE.WITHDRAW },
        ];
      }
      if (this.tab == 2) {
        return [
          { label: '全部', value: '1' },
          { label: '充值', value: '2' },
          { label: '扣款', value: '3' },
          { label: '转账', value: '5' },
        ];
      }
    },
    // 获取时间选择器默认时间，默认近三十天
    getNearMonthTime() {
      let currentTime = dayjs().format('YYYY-MM-DD') + ' 23:23:59';
      let lastMonthTime =
        dayjs().subtract(29, 'day').format('YYYY-MM-DD') + ' 00:00:00';
      return [lastMonthTime, currentTime];
    },
  },
  watch: {
    currency(n) {
      if (n) {
        this.getPaymentChannelOptions();
      }
    },
  },
  mounted() {
    this.getPaymentChannelOptions();
  },
  methods: {
    /**
     * 页面触发
     */
    // 获取支付方式
    getPaymentChannelOptions() {
      const options = {
        currency: this.currency,
        MUTE_WARNING: 1,
      };
      getPaymentChannel(options)
        .then((res) => {
          // console.log(res,'res');
          if (res?.errcode == 0) {
            this.paymentChannelOptions = res?.data || {};
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 关键字搜索输入框的值
    handleChangeKeyword(key, value) {
      this.searchForm.keys = key;
      this.searchForm.value = value;
      this.handleChangeSearchForm();
    },
    // 搜索表单
    handleChangeSearchForm(reData = false) {
      let formData = this.filterEmptyKey(Object.assign({}, this.searchForm));
      // 特殊处理时间字段
      if (formData?.create_time?.length > 0) {
        formData.create_start = formData.create_time[0];
        formData.end_start = formData.create_time[1];
        delete formData.create_time;
      }
      if (!formData?.value || formData?.value == '') {
        delete formData.key;
      }
      this.$emit('search', formData);
    },
    // 点击重置按钮
    handleClickReset() {
      this.searchForm = {
        business_type: '', // 交易类型
        payment_channel: '', //支付方式
        create_time: this.getNearMonthTime, // 交易完成时间
        keys: '', //下拉加输入框key
        value: '', //下拉加输入框value
        security_deposit: '', //保证金
      };
      if (this.$refs?.XmSelectOption) {
        this.$refs?.XmSelectOption?.reVal();
      }
      this.handleChangeSearchForm();
    },
    // 筛选回显
    echoSearchData(info) {
      this.pageNum = info?.pages || 1;
      this.searchForm = { ...info, create_time: [] };
      // 时间范围
      if (info?.create_start && info?.end_start) {
        this.searchForm.create_time = [info?.create_start, info?.end_start];
      }
      // 下拉加输入框回显
      if (this.$refs.XmSelectOption) {
        this.$refs.XmSelectOption?.reVal(info?.key, info?.value);
      }
    },
  },
  beforeDestroy() {},
};
