// 交易记录查看详情弹窗展示配置

// 充值 prop是后端返回参数
export const RECHARGE_DETAIL_CONFIG = [
  { key: '充值类型', prop: 'business_type_text' },
  { key: '金额', prop: 'actual_amount' },
  { key: '原因', prop: 'recharge_reason' },
  {
    key: '图片',
    prop: 'recharge_img',
    type: 'pic',
    showFileTitle: false,
    width: '100px',
    height: '100px',
  },
];

// 扣除 prop是后端返回参数
export const REDUCE_DETAIL_CONFIG = [
  { key: '扣除类型', prop: 'business_type_text' },
  { key: '金额', prop: 'actual_amount' },
  { key: '原因', prop: 'refuse_reason' },
  {
    key: '图片',
    prop: 'proof_img',
    type: 'pic',
    showFileTitle: false,
    width: '100px',
    height: '100px',
  },
];
