
import { COMMON_LABELS } from '../js/label_config_common';
// 变量
import { curDefaultLanguage } from '@/utils/cur_default_config.js'; //默认语言
export default {
  name: 'FoldTable',
  props: {
    // 是否在加载数据
    loading: {
      type: Boolean,
      default: false,
    },
    // 页面语言
    lang: {
      type: String,
      default: curDefaultLanguage,
    },
    width: {
      type: String,
      default: 'auto',
    },
    // 代表表格格式的列表
    tableFormat: {
      type: Array,
      default: () => [],
    },
    // 表格数据
    tableData: {
      type: Array,
      default: () => [],
    },
    // 单选框的label，选中某行表格后传出的值
    idProp: {
      type: String,
      default: '',
    },
    linkLabel: {
      type: String,
      default: '点击展开更多表格数据',
    },
    linkUnfoldLabel: {
      type: String,
      default: '收起表格数据',
    },
    // hasOperationCol: {
    //   type: Boolean,
    //   default: false,
    // },
    hasFolder: {
      type: Boolean,
      default: false,
    },
    // 折叠后表格最多展示的数据条数
    foldNum: {
      type: Number,
      default: 2,
    },
    height: {
      type: String,
      default: '160px',
    },
  },
  data() {
    return {
      isTableFold: true, // 当前表格是否展开
      selected: '', // el-radio 选中的仓库id
      displayTableData: [],
      fullTableData: [],
      tableHeight: this.height,
    };
  },
  watch: {
    tableData: {
      handler: function (newVal, oldVal) {
        this.fullTableData = newVal?.length > 0 ? newVal.slice() : [];
        this.handleUpdateDisplayData();
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    // 标签配置
    labelConfig() {
      return COMMON_LABELS[this.lang || curDefaultLanguage];
    },
    // 选中的表格行ID
    selectedRowId() {
      if (!this.selected) {
        // 默认选中表格第一个数据
        this.selected = this.displayTableData?.[0]?.[this.idProp] || '';
        this.handleSelectRow(this.selected); // 传出默认选择
      }
      return this.selected;
    },
    tableStyle() {
      return {
        '--table-height': this.height,
        width: this.width,
      };
    },
  },
  methods: {
    // 表格单元格的公共样式
    tableCommomCellStyle() {
      return {
        height: '40px',
        padding: '0px',
        fontSize: '14px',
        fontWeight: '400',
        textAlign: 'left',
        borderColor: '#E4E7ED',
      };
    },
    // 表格表头行的单元格样式
    tableHeaderCellStyle() {
      const commonStyle = this.tableCommomCellStyle();
      const myStyle = {
        background: '#F7F7F7',
        color: '#666',
        paddingLeft: '8px',
        borderBottom: '0',
      };
      return Object.assign(commonStyle, myStyle);
    },
    // 表格非表头行单元格样式
    tableCellStyle() {
      const commonStyle = this.tableCommomCellStyle();
      const myStyle = {
        color: '#333',
        padding: '0px',
        paddingLeft: '7px',
        paddingRight: '7px',
      };
      return Object.assign(commonStyle, myStyle);
    },
    // 获取物流表格待复制的字符串
    getLogCopyText(row) {
      return `${row.fre_id}-${row.cha_id}-${row.pro_id}`;
    },
    handleEmptyValue(val) {
      return val ? val : '--';
    },
    // 清空选中的表格行id
    clearSelected() {
      this.selected = '';
    },
    // 设置被选中的表格行id
    setSelectedRowId(id) {
      this.selected = id;
    },
    // 更新展示的表格数据
    handleUpdateDisplayData() {
      let arr = this.fullTableData.slice();
      if (this.isTableFold) {
        arr = this.fullTableData.slice(0, this.foldNum);
      }
      this.displayTableData = arr;
    },
    // 点击展开表格时，触发父组件事件
    handleClickMoreData() {
      this.isTableFold = !this.isTableFold;
      this.handleUpdateDisplayData();
    },
    // 点击操作列的复选框
    // handleCheckRow(item) {
    //   this.fullTableData.forEach(x => {
    //     if (x.cha_id != item.cha_id) {
    //       x.checked = false;
    //     }
    //   });
    //   this.$emit('check-row', item);
    // },
    // 选中某一表格行的单选按钮
    handleSelectRow(val) {
      if (val) {
        this.selected = val;
        this.$emit('select', this.selected);
      }
    },
    // 表格排序
    handleSortChange(row) {
      let { column, order, prop } = row;
      let sortMethod = column.sortMethod;
      if (order === 'ascending') {
        this.fullTableData.sort(sortMethod);
      } else if (order === 'descending') {
        this.fullTableData.sort((a, b) => {
          return sortMethod(b, a);
        });
      } else {
        this.fullTableData = this.tableData;
      }
      this.handleUpdateDisplayData();
    },
    // 迭代2.40 新增批量下单后 注释这块代码
    // 点击物流行的复制按钮
    // handleClickCopyLogId(row) {
    //   this.copyText(this.getLogCopyText(row));
    // },
  },
};
