
import { math } from '@/utils/math.js';
import sha1 from 'sha1';
import md5 from 'js-md5';
import { language } from '../static/switchLanguage';
import { addUserOrder } from '@/api/client-api/goods/GoodsAttention.js';
import { getFundInfo } from '@/api/client-api/fund/fundCenter.js';
/**
 * 多语言配置
 */
import { ARREARS_FONT } from '../static/label_config_tip';
export default {
  data() {
    return {
      languageObj: language,
      currentLanguage: {},
      item_tid: '', // 当前商品id
      skuIds: '', // skuid
      warId: '', // 仓库id
      startCountryid: '', // 发货地
      waitPayLoading: false, // 加入待付款wait
      placeOrderLoading: false, // 提交订单wait
      remark: '', // 订单备注

      arrearsLength: 0, // 欠费列表总数
      payArrearsTipDialog: false, // 欠费支付提示弹窗状态
    };
  },
  mounted() {
    this.item_tid = this.$route.query.item_id;
    this.skuIds = this.$route.query.sku_id;
    this.warId = this.$route.query.war_id;
    this.startCountryid = this.$route.query.sco_id;
  },
  computed: {
    currLanguage() {
      return this.$store.state.common.currLanguage;
    },
    // 补贴扣减的金额
    subsidyDiscountPrice() {
      // 无法享受补贴的且有批发价格的不补贴
      let price =
        !this.hasSkuSubsidy || this.reachStockUp
          ? 0
          : Math.max(0, this.goodPrice - this.firstGoodsPrice);
      return price ? price.toFixed(2) : 0;
    },
    // 批发政策扣减的金额
    stockUpDiscountPrice() {
      let price = this.reachStockUp
        ? this.goodNum * Math.max(0, this.originalPrice - this.goodPrice)
        : 0;
      return price ? price.toFixed(2) : 0;
    },
    // 欠费弹窗多语言配置
    arrearsLangConfig() {
      return ARREARS_FONT[this.lang || this.currLanguage];
    },
  },
  watch: {
    currLanguage: {
      handler(val) {
        if (val == 'zh-cn') {
          this.currentLanguage = this.languageObj.chObj;
        } else if (val == 'en') {
          this.currentLanguage = this.languageObj.enObj;
        }
      },
      immediate: true,
    },
  },
  props: {
    // 页面语言
    lang: {
      type: String,
      default: '',
    },
    logisticsfees: {
      type: String,
      default: '0',
    },
    // 划线价/原价
    originalPrice: {
      type: Number,
      default: 0,
    },
    // 非首件sku价格
    goodPrice: {
      type: Number,
      default: 0,
    },
    // 首件sku价格（已处理过是否补贴）
    firstGoodsPrice: {
      type: Number,
      default: 0,
    },
    // 是否达到批发标准
    reachStockUp: {
      type: Boolean,
      default: false,
    },
    // 是否享受补贴优惠
    hasSkuSubsidy: {
      type: Boolean,
      deafault: true,
    },
    goodNum: {
      type: Number,
      default: 0,
    },
    operationFee: {
      type: [String, Number],
      default: 0,
    },
    currentAddress: {
      type: Object,
      default: () => {},
    },
    currentteplate: {
      type: Object,
      default: () => {},
    },
    orderType: {
      type: [String, Number],
      default: '',
    },
    // 订单来源
    orderSource: {
      type: String,
      default: '',
    },
    // 其他订单来源
    otherOrderSource: {
      type: String,
      default: '',
    },
    supplierId: {
      type: String,
      default: '',
    },
    declareValue: {
      type: [String, Number],
      default: '',
    },
    wareType: {
      type: String,
      default: '1',
    },
    currentCou: {
      type: String,
      default: '',
    },
    currentCouNum: {
      type: [Number, String],
      default: 0,
    },
    wareLogistics: {
      type: String,
      default: '',
    },
    insuranceFee: {
      type: String,
      default: '0',
    },
  },
  methods: {
    // 加入待付款
    async joinWaitPay() {
      if (this.waitPayLoading) return;
      try {
        this.waitPayLoading = true;
        await this.judgeArrearsInfo();
        if (this.arrearsLength) {
          this.payArrearsTipDialog = true;
          this.waitPayLoading = false;
          return;
        }
        let res = await this.initialSubmitOrder();
        if (res && res.errcode == 0) {
          this.$router.push('/trade/nopay');
        }
        this.waitPayLoading = false;
      } catch (err) {
        this.waitPayLoading = false;
        console.error(err);
      }
    },
    // 下单
    async subOrder() {
      if (this.placeOrderLoading) return;
      try {
        this.placeOrderLoading = true;
        await this.judgeArrearsInfo();
        if (this.arrearsLength) {
          this.payArrearsTipDialog = true;
          this.placeOrderLoading = false;
          return;
        }
        let res = await this.initialSubmitOrder();
        if (res && res?.errcode == 0) {
          this.$router.push({
            path: '/paychose',
            query: {
              tid: res.data,
            },
          });
          this.$store.commit('pay/addBulkPaymentId', res.data);
        } else {
          // this.$message.error('下单失败');
        }
        this.placeOrderLoading = false;
      } catch (err) {
        console.error(err);
        this.placeOrderLoading = false;
      }
    },
    async initialSubmitOrder() {
      try {
        let typeAddress = '';
        let typelog = '';
        let typedeclared = '';
        let typeFail = '';
        let typeOrderSource = '';
        if (this.currLanguage == 'zh-cn') {
          typeAddress = '请选择收货地址';
          typelog = '请选择物流模板';
          typedeclared = '请填写申报价值';
          typeFail = '下单失败';
          typeOrderSource = '请填写订单来源';
        } else {
          typeAddress = 'Please select a shipping address';
          typelog = 'Please select a logistics template';
          typedeclared = 'Please fill in the declared value';
          typeFail = 'Failed to place an order';
          typeOrderSource = 'Please select your order source';
        }
        if (!this.currentAddress.id) {
          this.$message.error(typeAddress);
          return;
        }
        if (!this.currentteplate.fre_id) {
          this.$message.error(typelog);
          return;
        }
        if (this.currentAddress.country_id != 44 && !this.declareValue) {
          this.$message.error(typedeclared);
          return;
        }
        // 校验订单来源
        // #memo 是否有选择订单来源？如果订单来源选择“其他”，则是否有填入其他平台？
        // #TODO 得到产品确认，订单来源目前是国内分销商特有
        // if (
        //   !this.orderSource ||
        //   (this.orderSource == '-1' && !this.otherOrderSource)
        // ) {
        //   this.$message.error(typeOrderSource);
        //   return;
        // }
        let itemInfo = [
          {
            item_id: this.item_tid,
            sku_id: this.skuIds,
            num: Number(this.goodNum),
            trade_type: this.orderType,
          },
        ];
        if (this.currentAddress.country_id != 44) {
          itemInfo[0].declare_value = this.declareValue; // 申报价值
        }
        let submissionTime = new Date().getTime();

        let encryptionStr = JSON.stringify(itemInfo);

        encryptionStr = md5(encryptionStr);

        encryptionStr = sha1(String(submissionTime) + encryptionStr);

        let addrInfo = {
          country_id: this.currentAddress.country_id,
          province_id: this.currentAddress.province_id,
          city_id: this.currentAddress.city_id,
          region_id: this.currentAddress.region_id || '', // 区的id
          address: this.currentAddress.address,
          zip: this.currentAddress.zip,
          username: this.currentAddress.username,
          phone: this.currentAddress.phone,
          dialing_code: this.currentAddress.dialing_code,
        };
        let freData = {
          start: this.startCountryid,
          end: this.currentAddress.country_id,
          supplier_id: this.supplierId,
          war_id: this.warId,
          fre_id: this.currentteplate.fre_id, // 物流模板id
          cha_id: this.currentteplate.cha_id, // 规则id
          pro_id: this.currentteplate.pro_id, //
        };
        let options1 = {
          itemInfo: itemInfo,
          sign: encryptionStr,
          submission_time: submissionTime,
          addrInfo: addrInfo,
          freData: freData,
          order_from: 'pc',
          trade_type: this.orderType,
          order_source: this.orderSource,
        };
        // #TODO 得到产品确认，订单来源目前是国内分销商特有，不过先注释观望
        // if (this.orderSource == -1) {
        //   // 选择“其他”时，传用户填写的其他平台名称
        //   options1.other_order_source = this.otherOrderSource;
        // }
        if (this.currentCou) {
          options1.coupon_id = this.currentCou;
        }
        // 备注
        if (this.remark?.trim()) {
          options1.remark = this.remark?.trim();
        }
        let body = {
          body: JSON.stringify(options1),
          API_LANG_TYPE: this.currLanguage,
          MUTE_WARNING: 1,
        };

        let res = await addUserOrder(body);
        if (res?.errcode == 0) {
          return res;
        } else if (res?.errcode == 80205) {
          // #TODO 遇到这个状态码跳去商品详情页（类似商品下架等，后端定的）
          this.placeOrderLoading = true;
          this.waitPayLoading = true;
          this.$message.error(res.msg);
          window.open(this.item_tid + '.html', '_self');
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 判断是否有欠费信息
    async judgeArrearsInfo() {
      try {
        let options = {
          currency: this.lang == 'en' ? '2' : '1',
          API_LANG_TYPE: this.lang,
          MUTE_WARNING: 1,
        };
        const res = await getFundInfo(options);
        if (res?.errcode == 0) {
          this.arrearsLength = res?.data?.arrears_num || 0;
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 关闭弹窗
    closeDialog() {
      this.payArrearsTipDialog = false;
    },
    // 欠费信息弹窗点击去支付
    goPayArrears() {
      this.payArrearsTipDialog = false;
      let link = this.lang == 'en' ? '2' : '1';
      this.$router.push('/fund/fundcenter/' + link);
    },
    allcost() {
      let num =
        Number(this.logisticsfees) +
        Number(this.operationFee) +
        Number(this.insuranceFee);
      if (this.wareLogistics) {
        num = num + Number(this.wareLogistics) * this.goodNum;
      }
      // 这里改为补贴限制的逻辑
      let couNum =
        this.firstGoodsPrice + Math.max(0, this.goodNum - 1) * this.goodPrice;
      if (!isNaN(Number(this.currentCouNum))) {
        couNum =
          couNum - Number(this.currentCouNum) < 0
            ? 0
            : couNum - Number(this.currentCouNum);
      }
      num = num + couNum;
      if (!num) return 0;
      return Number(math.add(num))?.toFixed(2);
    },
    // 这里改为补贴限制的逻辑
    totalGoodAmount() {
      let num =
        this.firstGoodsPrice + Math.max(0, this.goodNum - 1) * this.goodPrice;
      return num.toFixed(2);
    },
    // 总的商品原价（划线价*商品数量）
    totalOriginalGoodsAmount() {
      let num = this.goodNum * this.originalPrice;
      return num.toFixed(2);
    },
  },
};
