
// 分页器多语言标签配置
const PAGE_TOOLS_LABELS = {
  'zh-cn': {
    prev: '上一页',
    next: '下一页',
  },
  en: { prev: 'Back', next: 'Next' },
};
export default {
  name: 'pagTools',
  data() {
    return {
      currentpage: '', // 输入框输入想要跳转到的页面
      curPages: this.pages, // 页数
      curPageSize: this.pageSize, // 每页展示几条数据
      allPage: '', //总页数
      currentpage1: 1,
      pageSizeOptions: [
        { value: 50, label: '50' },
        { value: 40, label: '40' },
        { value: 30, label: '30' },
        { value: 20, label: '20' },
        { value: 10, label: '10' },
      ], //条数控制
    };
  },
  props: {
    // 使用的语言
    // 'zh-cn': 默认简体中文；'en': 英文
    lang: {
      type: String,
      default: 'zh-cn',
    },
    // 列表数据总数
    total: {
      type: [Number, String],
      default: 0,
    },
    // pageSize:number, 每页展示几条数据
    pageSize: {
      type: [Number, String],
      default: 10,
    },
    // 页数
    pages: {
      type: [Number, String],
      default: 1,
    },
    // 是否显示分页器后面的快速跳转模块
    showJump: {
      type: Boolean,
      default: true,
    },
    // 控制是否需要自定义每页条数展示
    isPageSize: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // 标签多语言配置
    labelConfig() {
      return PAGE_TOOLS_LABELS[this.lang || 'zh-cn'];
    },
  },
  watch: {
    currentpage: function (n) {
      if (!n) {
        return;
      }
      if (Number(n) < this.allPage) {
        this.currentpage = Number(n);
      } else {
        this.currentpage = this.allPage;
      }
    },
    pages: function (page) {
      if (page) {
        this.currentpage = '';
        this.curPages = page;
      }
    },
    // 监听pageSize，例如切换布局pageSize不一样时，更新curPageSize的值。
    pageSize(pageSize) {
      this.curPageSize = pageSize;
    },
  },
  methods: {
    // 总页数
    totalPageNum() {
      if (this.total == null || this.total == '') {
        return 0;
      } else {
        if (this.curPageSize != 0 && this.total % this.curPageSize == 0) {
          this.allPage = parseInt(this.total / this.curPageSize);
          return parseInt(this.total / this.curPageSize);
        }
        if (this.curPageSize != 0 && this.total % this.curPageSize != 0) {
          this.allPage = parseInt(this.total / this.curPageSize) + 1;
          return parseInt(this.total / this.curPageSize) + 1;
        }
      }
    },
    // 改变每页展示的条数
    changPageSize() {
      // 当前页数大于总页数时，让当前页数等于总页数
      if (this.curPages > this.totalPageNum()) {
        this.curPages = this.allPage;
      }
      this.$emit('change-pagesize', this.curPages, this.curPageSize);
    },
    // 输入想要跳转的页数，点击确定
    goPageBtn() {
      if (!this.currentpage) {
        this.$message({
          type: 'warning',
          message: '请选择要跳转的页码',
        });
        return;
      }
      this.curPages = Number(this.currentpage);
      this.currentpage1 = this.curPages;
      this.$emit('goPageBtn', this.curPages);
    },
    currentChange(n) {
      this.curPages = n;
      this.currentpage1 = this.curPages;
      this.$emit('currentChange', n);
    },
    goPage(n) {
      if (this.total === 0) {
        return;
      }
      this.curPages += n;
      this.currentpage1 = this.curPages;
      this.$emit('goPageNum', this.curPages, this.curPageSize);
    },
    // 回到初始页面 加ref调用该方法
    changecurPage(n = 1) {
      this.curPages = n;
    },
  },
};
