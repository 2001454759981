
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
// 加载插件
dayjs.extend(utc);
dayjs.extend(timezone);
// api
import { getTrajectoryList } from '@/api/client-api/trade/orderlist';
export default {
  name: 'LogisticsDetails',
  components: {},
  props: {
    // 订单id
    tid: {
      type: String,
      default: '',
    },
    // 物流单号
    logisticsOrderNum: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialogVisible: true, // el-dialog默认打开,父组件控制显隐
      logisticsDetail: [], // 物流详情
    };
  },
  methods: {
    // 转换时间格式 TODO: 产品说暂时展示北京时间
    changeTimeDate(time) {
      return dayjs.utc(time).tz('Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss');
    },
    // 关闭弹窗
    closeDialog() {
      this.$emit('close-dialog');
    },
    // 获取物流轨迹信息
    async getTrajectory() {
      try {
        let options = {
          tid: this.tid,
        };
        if (this.logisticsOrderNum) {
          options.logistics_no = this.logisticsOrderNum;
        }
        const res = await getTrajectoryList(options);
        if (res?.errcode == 0) {
          this.logisticsDetail = res?.data || [];
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
  mounted() {
    this.getTrajectory();
  },
};
