
import leftmenu from '@/components/comme/leftmenu.vue';
export default {
  name: 'shopHomeCapital',
  components: {
    leftmenu,
  },
  data() {
    return {
      leftname: '资金',
      /** menulist:[
            '资金中心',
            '交易记录',
            '历史账单',
            '发票管理',
            '银行卡管理',
            '货币兑换',
          ], */
      menulist: ['资金中心', '优惠券', '账单中心', '发票管理', '提现记录'], // '银行卡管理'
      // routerlist: [
      //   "/fund/fundcenter",
      //   "/fund/transactionrecords",
      //   "/fund/historicalBill",
      //   "/fund/invoice",
      //   "/fund/bankCard",
      //   "/fund/currencyExchange",
      // ],
      routerlist: [
        '/fund/fundcenter',
        '/fund/coupon',
        '/fund/transactionrecords',
        '/fund/invoice',
        '/fund/withdraw',
      ],
      // 路由路径的 index 哈希查询表
      // 通过路径字符串查询路由index
      /**
       * 以后增加菜单路由，菜单的子菜单的 hash 值应当与主菜单项保持一致
       * 比如，银行卡管理有一个子菜单“添加银行卡”（/bankAdd)
       * 则 /bankAdd 的 hash 值应当设置为 /bankCard 的 hash 值（i.e. 2）
       */
      routerIndexHash: {
        '/fund/fundcenter': 0,
        '/fund/coupon': 1,
        '/fund/transactionrecords': 2,
        '/fund/recorddetails': 2,
        // "/fund/bankCard": 2,
        // "/fund/bankAdd": 2,
        // "/fund/bankEdit": 2,
        '/fund/invoice': 3,
        '/fund/invoicingInformation': 3, // 开票信息
        '/fund/inviceDetail': 3, // 发票明细
        '/fund/withdraw': 4, // 提现记录
      },
    };
  },
  methods: {},
  //计算属性computed无法传递参数
  computed: {},
  mounted() {},
};
