
import DesignatedProducts from './DesignatedProducts.vue';
export default {
  name: 'CouponShowBox',
  components: { DesignatedProducts },
  props: {
    // 优惠券详情
    couponDetail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      goodShow: false,
    };
  },
  computed: {
    // 类型展示图片1未使用 2使用中 3已使用 4已过期 5回收站
    imgShow() {
      switch (this.couponDetail?.status?.toString()) {
        case '2':
          return require('@/assets/fund/inuse.png');
        case '3':
          return require('@/assets/fund/used.png');
        case '4':
          return require('@/assets/fund/expired.png');
        case '5':
          return require('@/assets/fund/recycled.png');
        default:
          return '';
      }
    },
    // 适用范围文案
    applicableScopeText() {
      let text = '';
      if (this.couponDetail.use_scope_type == 1) {
        text = '全部商品可使用';
      } else if (this.couponDetail.use_scope_type == 2) {
        // 指定类目
        let length = this.couponDetail.use_scope.length - 1;
        text = this.couponDetail.use_scope[length]?.name;
      } else if (this.couponDetail.use_scope_type == 3) {
        let arr = this.couponDetail.use_scope.map((item) => {
          return item.title;
        });
        text = arr.join(',');
      }
      return text;
    },
  },
  filters: {},
  mounted() {},
  methods: {
    // 点击立即使用按钮
    immediateUse(obj) {
      switch (obj.use_scope_type) {
        case '1':
          // 全部商品
          this.$router.push('/');
          break;
        case '2':
          // 指定类目商品
          let length = obj.use_scope.length - 1;
          this.$router.push('/list_' + obj.use_scope[length]?.id + '.html');
          break;
        case '3':
          // 指定商品
          this.goodShow = true;
          break;
      }
    },
    // 关闭商品弹框
    closeGoodIdag() {
      this.goodShow = false;
    },
    // 选中优惠券
    choseCoupon() {
      this.$emit('chose-coupon', this.couponDetail?.coupon_no);
    },
    // 已使用的优惠券跳转订单详情
    goOrderDetail(tid) {
      if (this.couponDetail?.status == '3' && tid) {
        let pathHerf = this.$router.resolve({
          path: '/trade/orderdetails', // 跳转的路径
          query: {
            item_id: tid, // query 要传递携带的参数
          },
        });
        window.open(pathHerf.href, '_blank');
      }
    },
  },
};
