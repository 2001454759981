
/**
 * 多语言配置
 */
import { PLATE_CORRELATION } from '../js/label_config_board';
// 变量
import {
  curDefaultLanguage,
  CUR_TOKEN_NAME,
} from '@/utils/cur_default_config.js'; //默认语言
export default {
  name: 'NoShadowBox',
  props: {
    // 页面语言
    lang: {
      type: String,
      default: curDefaultLanguage,
    },
    // 商品信息
    goodsMsg: {
      type: Object,
      default: null,
    },
    // 是否滚动状态
    isTransition: {
      type: Boolean,
      default: true,
    },
    // 是否有Top标志
    hasTopTip: {
      type: Boolean,
      default: false,
    },
    // 是否有收藏商品样式
    hasCollect: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      token: '',
    };
  },
  computed: {
    // 标签多语言配置
    langConfig() {
      return PLATE_CORRELATION[this.lang || curDefaultLanguage];
    },
  },
  mounted() {
    this.token = this.$cookies.get(CUR_TOKEN_NAME);
  },
  methods: {
    // 点击收藏商品
    isCollectGoods(e, item) {
      if (e.target.tagName !== 'A') {
        e.preventDefault();
        // 处理点击子元素的逻辑
        e.stopPropagation();
        if (!this.token) {
          this.$router.push('/login');
          return;
        }
        this.$emit('click-collect', item);
      }
    },
  },
};
