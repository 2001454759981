
import bankCardTransfer from './bankCardTransfer.vue';
/**
 * 多语言配置
 */
import { BALANCE_RECHARGE } from '../js/label_config_balance';
import { Decimal } from 'decimal.js'; // 精度计算
// 变量
import { curDefaultLanguage } from '@/utils/cur_default_config.js'; //默认语言
export default {
  name: 'USDRecharge',
  components: { bankCardTransfer },
  props: {
    // 页面语言
    lang: {
      type: String,
      default: curDefaultLanguage,
    },
    // 充值金额
    amount: {
      type: [Number, String],
      default: '',
    },
    // 当前的账户余额
    currentBalance: {
      type: [Number, String],
      default: '',
    },
    // 充值类型
    target_type: {
      type: String,
      default: '1',
    },
  },
  data() {
    return {
      backCardShow: false, // 选择银行卡支付的弹窗状态
      waitTipDialog: false, // 等待审核的提示弹窗状态

      currency: '1', // 币种类型 1-人民币 2-美元
    };
  },
  computed: {
    // 标签多语言配置
    langConfig() {
      return BALANCE_RECHARGE[this.lang || curDefaultLanguage];
    },
    // 充值后余额
    afterPayShow() {
      return new Decimal(this.currentBalance || 0)
        .plus(new Decimal(this.amount || 0))
        .toFixed(2);
    },
    // 充值金额保留2位小数
    amountTwoDecimal() {
      if (!this.amount) return '0.00';
      return Number(this.amount)?.toFixed(2);
    },
  },
  mounted() {
    this.currency = this.$route.params.currency;
  },
  methods: {
    // 点击立即支付按钮
    payAmount() {
      if (!this.amount || !Number(this.amount)) {
        this.$message.warning(this.langConfig['input-amount-tip']);
        return;
      }
      this.backCardShow = true;
    },
    // 关闭银行卡支付弹窗
    closeBankDialog(type) {
      this.backCardShow = false;
      // type == 1是提交后的关闭
      if (type == '1') {
        this.waitTipDialog = true; // 打开等待审核提示弹窗
      }
    },
    // 点击提示弹窗关闭按钮
    closeConfig() {
      this.$router.push('/fund/fundcenter/2'); // 跳转回资金中心
      this.waitTipDialog = false;
    },
  },
};
