// 公共请求接口
import request from '@/utils/request.js';

// 获取国家列表
export function getCountries(data) {
  return request({
    url: 'user/getCountries',
    method: 'get',
    params: data,
  });
}
// 获取省列表
export function getStates(data) {
  return request({
    url: 'user/getStates',
    method: 'get',
    params: data,
  });
}
// 获取市列表
export function getCity(data) {
  return request({
    url: 'user/getCity',
    method: 'get',
    params: data,
  });
}
// 获取区列表
export function getRegions(data) {
  return request({
    url: 'user/getRegions',
    method: 'get',
    params: data,
  });
}

// 获取洲列表
export function getContinents(data) {
  return request({
    url: '/user/getContinents',
    method: 'get',
    params: data,
  });
}

//获取物流模板
export function getItemFreight(data) {
  return request({
    url: '/ShopTrade/getItemFreight',
    method: 'get',
    params: data,
    controller: {
      openLoading: data?.openLoading || false,
    },
  });
}

// 获取分销商信息
export function getDistributorInfo() {
  return request({
    url: '/adminUser/getDistributorInfo',
    method: 'get',
  });
}
/**
 * 获取指定仓库下的指定sku的操作费计算规则
 * @param {*} data
 * @returns
 */
export function getGoodOperationFeeRule(data) {
  return request({
    apiModule: 'ware',
    url: '/warehouseGoods/getGoodsOperatingFeeRule',
    method: 'get',
    params: data,
  });
}

// 验证原来手机号的验证码
export function sendVerificationSms(data) {
  return request({
    url: '/sms/sendVerificationSms',
    method: 'get',
    params: data,
  });
}

//发送验证码
export function sendSmsCode(data) {
  return request({
    url: '/sms/sendSmsCode',
    method: 'get',
    params: data,
  });
}
// 设置用户支付密码
export function setPaymentPassword(data) {
  return request({
    url: '/user/setPaymentPassword',
    method: 'post',
    data: data,
  });
}

// 更换手机号验证
export function editPhone(data) {
  return request({
    apiModule: 'user',
    url: '/user/editPhone',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}

// 前端预警接口
export function abnormalAlarm(data) {
  return request({
    url: '/FrontEndAbnormalAlarm/abnormalAlarm',
    method: 'post',
    data: data,
  });
}

// 访问限制
export function visitsLimit(data) {
  return request({
    url: 'VisitsLimit/visitsLimit',
    method: 'get',
    params: data,
  });
}
// 获取可用城市id
export function getAdressCities(data) {
  return request({
    url: '/order/getAdressCities',
    method: 'get',
    params: data,
  });
}
// 获取优惠卷列表
export function getCouponsList(data) {
  return request({
    url: '/item/getCouponsList',
    method: 'get',
    params: data,
  });
}

// 页面浏览记录保存
export function getBrowseRecord(data) {
  return request({
    url: '/BuryPoint/getBrowseRecord',
    method: 'post',
    data: data,
  });
}

// 导出文件导出列表
export function exportTaskList(data) {
  return request({
    url: '/exportTask/exportTaskList',
    method: 'post',
    data: data,
  });
}

