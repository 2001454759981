
import { language } from '../static/switchLanguage';
// import { shopTrade } from '@/api/client-api/goods/GoodsAttention.js';

export default {
  data() {
    return {
      languageObj: language,
      placeholder:'',
      orderlist: {},
      currentLanguage: {},
      item_tid: '', // 商品ID
      skuIds: '', // skuID
      warId: '', // 仓库ID

      widthObj: {
        // 长度对象（海外）
        goodWidth: 180, // 商品信息宽度
        attributWidth: 140, // 包装尺寸宽度
        priceWidth: 136, // 价格宽度
        numberWidth: 110, // 商品数量宽度
        stroreWidth: 113, // 店铺宽度
        wareWidth: 108, // 仓库宽度
        orderTypeWidth: 141, // 订单类型宽度
        declaredWidth: 116, // 申报价值宽度
      },
      declaredValue: null, // 申报价值
      maxDeclaredValue: 0, // 申报价值上限
      sampleAvailableAmount: 0, // 样品单，每单可购买数量
    };
  },
  props: {
    // 划线价
    originalPrice: {
      type: Number,
      default: 0,
    },
    goodPrice: {
      type: [String, Number],
      default: '0',
    },
    operationFee: {
      type: [String, Number],
      default: '0',
    },
    currentteplate: {
      type: Object,
      default: () => {},
    },
    orderDetail: {
      type: Object,
      default: () => {},
    },
    wareType: {
      type: String,
      default: '1',
    },
    wareLogistics: {
      type: String,
      default: '',
    },
  },
  computed: {
    // 动态css
    cssWidth() {
      return {
        '--goodWidth': this.widthObj.goodWidth + 'px',
        '--attributWidth': this.widthObj.attributWidth + 'px',
        '--priceWidth': this.widthObj.priceWidth + 'px',
        '--numberWidth': this.widthObj.numberWidth + 'px',
        '--stroreWidth': this.widthObj.stroreWidth + 'px',
        '--wareWidth': this.widthObj.wareWidth + 'px',
        '--orderTypeWidth': this.widthObj.orderTypeWidth + 'px',
        '--declaredWidth ': this.widthObj.declaredWidth + 'px',
      };
    },
    currLanguage() {
      return this.$store.state.common.currLanguage;
    },
    // 包装尺寸
    packageSize() {
      // 保留两位小数，避免 js 计算精度问题
      let size = Number(
        this.orderlist.length * this.orderlist.width * this.orderlist.height
      )?.toFixed(2);
      if (isNaN(size)) return '--';
      let str = `${this.orderlist.length}*${this.orderlist.width}*${this.orderlist.height}(cm)=${size}cm³`;
      return str;
    },
    // 商品属性
    currentSpecInfo() {
      let specStr = '';
      if (this.orderlist?.spec_info) {
        JSON.parse(this.orderlist.spec_info).forEach((item) => {
          specStr = specStr + item.name + ':' + item.value + ';';
        });
      }
      return specStr;
    },
    defaultDeclaredValue() {
      if (this.orderlist.num) {
        return Number(this.orderlist.num) * Number(this.goodPrice);
      } else {
        return 0;
      }
    },
  },
  watch: {
    currLanguage: {
      handler(val) {
        if (val == 'zh-cn') {
          // 中文
          this.currentLanguage = this.languageObj.chObj;
          // this.widthObj = {
          //   goodWidth: 175, // 商品信息宽度
          //   attributWidth: 199, // 包装尺寸宽度
          //   priceWidth: 124, // 价格宽度
          //   numberWidth: 118, // 商品数量宽度
          //   stroreWidth: 108, // 店铺宽度
          //   wareWidth: 201, // 仓库宽度
          //   orderTypeWidth: 125, // 订单类型宽度
          //   declaredWidth: 0, // 申报价值宽度
          // };
        } else if (val == 'en') {
          // 英文
          this.currentLanguage = this.languageObj.enObj;
          // this.widthObj = {
          //   goodWidth: 180, // 商品信息宽度
          //   attributWidth: 140, // 包装尺寸宽度
          //   priceWidth: 136, // 价格宽度
          //   numberWidth: 110, // 商品数量宽度
          //   stroreWidth: 113, // 店铺宽度
          //   wareWidth: 108, // 仓库宽度
          //   orderTypeWidth: 141, // 订单类型宽度
          //   declaredWidth: 116, // 申报价值宽度
          // };
        }
      },
      immediate: true,
    },
    wareType(val) {
      if (val == 2) {
        // 海外样式
        this.widthObj = {
          goodWidth: 180, // 商品信息宽度
          attributWidth: 140, // 包装尺寸宽度
          priceWidth: 136, // 价格宽度
          numberWidth: 110, // 商品数量宽度
          stroreWidth: 113, // 店铺宽度
          wareWidth: 108, // 仓库宽度
          orderTypeWidth: 141, // 订单类型宽度
          declaredWidth: 116, // 申报价值宽度
        };
      } else {
        // 海外样式
        this.widthObj = {
          goodWidth: 175, // 商品信息宽度
          attributWidth: 199, // 包装尺寸宽度
          priceWidth: 124, // 价格宽度
          numberWidth: 118, // 商品数量宽度
          stroreWidth: 108, // 店铺宽度
          wareWidth: 201, // 仓库宽度
          orderTypeWidth: 125, // 订单类型宽度
          declaredWidth: 0, // 申报价值宽度
        };
      }
      // console.log(val, "00000000");
      // this.divkey++;
    },
    currentteplate: {
      handler(value) {
        if (value.declared_value_max)
          this.maxDeclaredValue = Number(value.declared_value_max);
        if (
          this.declaredValue &&
          this.maxDeclaredValue &&
          Number(this.declaredValue) > this.maxDeclaredValue
        ) {
          this.declaredValue = this.maxDeclaredValue;
        }
      },
      immediate: true,
    },
    orderDetail: {
      handler(obj) {
        if (Object.keys(obj).length > 0) {
          this.orderlist = obj;
          this.sampleAvailableAmount = Number(obj.sample_available_amount);
          // 样品单数量为0，强制为普通单
          if (this.sampleAvailableAmount == 0) {
            this.orderlist.ordertype = 2;
          }
        }
      },
      immediate: true,
    },
    defaultDeclaredValue(val) {
      this.declaredValue = val;
      this.declareChange(this.declaredValue);
    },
  },
  mounted() {
    this.item_tid = this.$route.query.item_id;
    this.skuIds = this.$route.query.sku_id;
    this.warId = this.$route.query.war_id;
    // this.getItemInfo1();
  },
  methods: {
    // 获取订单详情
    // getItemInfo1() {
    //   const option = {
    //     item_id: this.item_tid,
    //     sku_id: this.skuIds,
    //     ware_id: this.warId,
    //     API_LANG_TYPE: this.currLanguage,
    //   };
    //   shopTrade(option)
    //     .then((res) => {
    //       if (res.errcode == 0) {
    //         res.data.num = this.$route.query.num; // 购买数量
    //         res.data.ordertype = "2"; //默认普通单
    //         this.orderlist = res.data;
    //         // this.stockDetail = res.data.stock_detail;
    //         this.$emit("getOrderDeatil", this.orderlist);
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err, "err");
    //     });
    // },

    // 商品数量变化
    numchanghe(type) {
      let num = Number(this.orderlist.ware_info.store);

      if (type == 1 && this.orderlist.num > 1) {
        this.orderlist.num--;
      } else if (type == 2 && this.orderlist.num < num) {
        this.orderlist.num++;
      } else if (type == 3 && this.orderlist.num > num) {
        this.orderlist.num = num;
      } else if (type == 3 && this.orderlist.num == 0) {
        this.orderlist.num = 1;
      }
      if (
        this.orderlist.ordertype == 1 &&
        this.orderlist.num > this.sampleAvailableAmount
      ) {
        this.orderlist.num = this.sampleAvailableAmount;
      }
      this.$emit('getOrderDeatil', this.orderlist);
    },
    orderTypeChange() {
      this.$emit('getOrderDeatil', this.orderlist);
    },
    // 申报价值
    declareChange(value) {
      // if (value && this.maxDeclaredValue) {
      //   if (Number(value) > this.maxDeclaredValue) {
      //     value = String(this.maxDeclaredValue);
      //     this.declaredValue = String(this.maxDeclaredValue);
      //   }
      // }
      this.$emit('getDeclare', value);
    },
  },
};
