import Vue from 'vue';
import { copyText } from './textCopy';
import { filterEmptyKey } from './utils';
import { digitalFormat } from './digitalFormat';
import axios from 'axios';
import IMG_ERROR_PLACEHOLDER from './imgConstant';
import QRCode from 'qrcode';
import { CS_ROUTE_NAME } from '@/utils/chat_tools.js';
// 记录用户筛选条件
import { getPageFilter } from '@/utils/page-filter/tool.js';
// 工具函数
import { CUR_TOKEN_NAME } from '@/utils/cur_default_config.js';
import { errcodeHandler } from '@/utils/res_error_handler';
let mixin = {
  data() {
    return {};
  },
  activated() {},
  filters: {
    imgbaseurl(value) {
      if (!value) return '';
      const procotol = value.substring(0, 4);
      const prefix = procotol == 'http' ? '' : 'https://img.ximu.cn/';
      return prefix + value;
    },
    /**
     * 设置图片的清晰度
     * @param {String} url 图片url
     * @returns 处理后的图片url
     */
    settingDistinctDegree(url) {
      if (!url) return '';
      const procotol = url.substring(0, 4);
      const prefix = procotol == 'http' ? '' : 'https://img.ximu.cn/';
      return prefix + url + '?imageView2/0/w/184/h/184/interlace/1/q/85';
    },
  },
  methods: {
    // 当前是否是本地环境或者 188 测试环境 或者 191 或者 190
    isLocalEnv() {
      return (
        process.env.NUXT_ENV.VUE_APP_ENV === 'local' ||
        process.env.NUXT_ENV.VUE_APP_ENV === 'dev-188' ||
        process.env.NUXT_ENV.VUE_APP_ENV === 'check-191' ||
        process.env.NUXT_ENV?.VUE_APP_ENV === 'check-193' ||
        process.env.NUXT_ENV.VUE_APP_ENV === 'release-190'
      );
    },
    /**
     * 设置图片属性
     * @param {String} url 图片url
     * @param {String} width 改变图片属性宽度
     * @param {String} height 改变图片属性高度
     * @returns 处理后的图片url
     * 改变图片大小
     */
    changeImgProperties(url, width = '200', height = '200') {
      if (!url) return '';
      const procotol = url.substring(0, 4);
      const prefix = procotol == 'http' ? '' : 'https://img.ximu.cn/';
      let finishImg =
        prefix +
        url +
        '?imageView2/0/w/' +
        width +
        '/h/' +
        height +
        '/interlace/1/q/85';
      return finishImg;
    },
    /**
     * 数字格式化
     * @param {Number} num 传进来的数字
     * @param {String} locale 语言环境
     * @param {Object} options Intl.NumberFormat KPI 相对应的配置(根据自己需要)
     * @returns 相对应的数字格式
     * options 配置详情 digitalFormat.js
     */
    digitalFormat(num = 0, locale = 'zh-CN', options = {}) {
      return digitalFormat(num, locale, options);
    },
    /**
     * 过滤掉对象中值为空的字段
     * @author hukeyi
     * @param {*} obj
     * @returns 有值的字段组成的新对象
     */
    filterEmptyKey(obj) {
      return filterEmptyKey(obj);
    },
    // 通过后缀名作文件判断
    matchType(fileName) {
      // 后缀获取
      var suffix = '';
      // 获取类型结果
      var result = '';
      try {
        var flieArr = fileName.split('.');
        suffix = flieArr[flieArr.length - 1]?.toLowerCase(); // 保证是小写字母
      } catch (err) {
        suffix = '';
      }
      // fileName无后缀返回 false
      if (!suffix) {
        result = false;
        return result;
      }
      // 图片格式
      var imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'jfif', 'webp'];
      // 进行图片匹配
      result = imglist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'image';
        return result;
      }
      // 匹配txt
      var txtlist = ['txt'];
      result = txtlist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'txt';
        return result;
      }
      // 匹配 excel
      var excelist = ['xls', 'xlsx'];
      result = excelist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'excel';
        return result;
      }
      // 匹配 word
      var wordlist = ['doc', 'docx'];
      result = wordlist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'word';
        return result;
      }
      // 匹配 pdf
      var pdflist = ['pdf'];
      result = pdflist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'pdf';
        return result;
      }
      // 匹配 ppt
      var pptlist = ['ppt'];
      result = pptlist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'ppt';
        return result;
      }
      // 匹配 视频
      var videolist = ['mp4', 'm2v', 'mkv'];
      result = videolist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'video';
        return result;
      }
      // 匹配 音频
      var radiolist = ['mp3', 'wav', 'wmv'];
      result = radiolist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'radio';
        return result;
      }
      // 其他 文件类型
      result = 'other';
      return result;
    },
    getBrowserType() {
      if (!navigator) return '';
      let userAgent = navigator.userAgent;
      console.log('user agent', navigator.userAgent);
      if (userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1) {
        return 'Opera';
      } else if (
        userAgent.indexOf('compatible') > -1 &&
        userAgent.indexOf('MSIE') > -1
      ) {
        return 'IE';
      } else if (userAgent.indexOf('Edg') > -1) {
        return 'Edge';
      } else if (userAgent.indexOf('Firefox') > -1) {
        return 'Firefox';
      } else if (
        userAgent.indexOf('Safari') > -1 &&
        userAgent.indexOf('Chrome') == -1
      ) {
        return 'Safari';
      } else if (
        userAgent.indexOf('Chrome') > -1 ||
        userAgent.indexOf('CriOS') > -1
      ) {
        return 'Chrome';
      } else if (!!window.ActiveXObject || 'ActiveXObject' in window) {
        return 'IE>=11';
      } else {
        return 'Unkonwn';
      }
    },
    //复制文本
    copyText(text) {
      copyText(text)
        ? this.$message.success('复制成功')
        : this.$message.warning('复制成功');
    },
    //下载文件(字符流)
    downloadFile(url, fileName) {
      let box = process.env.NUXT_ENV.VUE_APP_API + url;
      axios({
        url: box,
        method: 'get',
        headers: { token: localStorage.getItem(CUR_TOKEN_NAME) },
        responseType: 'blob',
      }).then((res) => {
        if (
          res?.data?.type === 'application/json' ||
          res?.data?.type === 'text/html'
        ) {
          res.data.text()?.then((text) => {
            if (text) {
              const resData = JSON.parse(text || '{}');
              errcodeHandler(resData.errcode);
            }
          });
        } else if (res) {
          const blob = new Blob([res.data]);
          const objectURL = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = objectURL;
          link.download = fileName;
          link.click();
        }
      });
    },
    // 链接下载
    down(url, fileName) {
      try {
        if (!url) return '';
        const procotol = url.substring(0, 4);
        const prefix = procotol == 'http' ? url : 'https://img.ximu.cn/' + url;
        axios({
          url: prefix + '?attname=' + fileName,
          method: 'get',
          responseType: 'blob',
        }).then((res) => {
          const filestream = res.data; // 返回的文件流
          const blob = new Blob([filestream], {
            type: res.headers['content-type'],
          });
          const a = document.createElement('a');
          const href = window.URL.createObjectURL(blob); // 创建下载连接
          a.href = href;
          a.download = decodeURI(fileName);
          document.body.appendChild(a);
          // console.log(a)
          // window.open(a.href)
          a.click();
          document.body.removeChild(a); // 下载完移除元素
          window.URL.revokeObjectURL(href); // 释放掉blob对象
        });
      } catch (error) {
        this.$message({ type: 'warning', message: error });
      }
    },
    // 生成二维码
    createQRcode(options, cb) {
      // 会返回一个base64的链接
      return QRCode.toDataURL(options.text, options, cb);
    },
    // 精度丢失
    strip(num, precision = 12) {
      return +parseFloat(Number(num).toPrecision(precision));
    },
    /**
     * 当前用户是否登录
     * @returns Boolean
     */
    isLogin() {
      let token = this.$cookies.get(CUR_TOKEN_NAME);
      return !!token;
    },
    /**
     * 判断是否是空对象
     * (null 和 undefined 会返回 false)
     * @author hukeyi
     * @param { Object } obj
     * @returns { Boolean }
     */
    isEmptyObject(obj) {
      return obj && JSON.stringify(obj) === '{}';
    },
    // 通过后缀名作文件判断
    matchType(fileName) {
      // 后缀获取
      var suffix = '';
      // 获取类型结果
      var result = '';
      try {
        var flieArr = fileName.split('.');
        suffix = flieArr[flieArr.length - 1]?.toLowerCase(); // 保证是小写字母
      } catch (err) {
        suffix = '';
      }
      // fileName无后缀返回 false
      if (!suffix) {
        result = false;
        return result;
      }
      // 图片格式
      var imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'jfif', 'webp'];
      // 进行图片匹配
      result = imglist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'image';
        return result;
      }
      // 匹配txt
      var txtlist = ['txt'];
      result = txtlist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'txt';
        return result;
      }
      // 匹配 excel
      var excelist = ['xls', 'xlsx'];
      result = excelist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'excel';
        return result;
      }
      // 匹配 word
      var wordlist = ['doc', 'docx'];
      result = wordlist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'word';
        return result;
      }
      // 匹配 pdf
      var pdflist = ['pdf'];
      result = pdflist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'pdf';
        return result;
      }
      // 匹配 ppt
      var pptlist = ['ppt'];
      result = pptlist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'ppt';
        return result;
      }
      // 匹配 视频
      var videolist = ['mp4', 'm2v', 'mkv'];
      result = videolist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'video';
        return result;
      }
      // 匹配 音频
      var radiolist = ['mp3', 'wav', 'wmv'];
      result = radiolist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'radio';
        return result;
      }
      // 其他 文件类型
      result = 'other';
      return result;
    },
    /**
     * 图片加载失败占位
     */
    handleImgError(event) {
      const img = event.target;
      // 用 base64 编码图片作为占位图片，避免出现 onerror 死循环导致递归爆栈
      img.src = IMG_ERROR_PLACEHOLDER;
    },
    /**
     * 节流函数
     * @param Function func 节流目标函数
     * @param String delay 延迟时间
     * @returns
     */
    throttle: function (func, delay) {
      let timer;
      return function () {
        let context = this;
        let args = arguments;
        if (!timer) {
          timer = setTimeout(() => {
            timer = null;
            func.apply(context, args);
          }, delay);
        }
      };
    },
    /**
     * 防抖函数
     * @param Function func 目标函数
     * @param String wait 时间
     * @returns
     */
    _debounce: function (func, wait) {
      let timeout;
      return function () {
        const context = this;
        const args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(context, args);
        }, wait);
      };
    },
    // 获取路由路径
    getStayRoute(routePath) {
      let stayRoute = '';
      // 判断是不是params传参方式 ===0可能不是,!==0是
      if (Object.entries(routePath.params).length !== 0) {
        let realPath = routePath.matched[routePath.matched.length - 1].path;
        // 判断有没有params传参 indexOf(':')=== -1没有 !== -1有
        if (realPath.indexOf(':') !== -1) {
          stayRoute = realPath.slice(0, realPath.indexOf(':') - 1);
        } else {
          stayRoute = realPath;
        }
      } else {
        stayRoute = routePath.path;
      }
      return stayRoute;
    },
    // 获取操作系统
    getOperatingSystem() {
      // 获取第一个左括号出现的下标
      let leftMarkIndex = navigator.userAgent.indexOf('(') + 1;
      let rightMarkIndex = navigator.userAgent.indexOf(';');
      let operatingSystem = navigator.userAgent.slice(
        leftMarkIndex,
        rightMarkIndex
      );
      return operatingSystem;
    },
    // 上报埋点数据
    burialPoint(dataList, print) {
      let options = {
        data: dataList,
        MUTE_WARNING: 1,
        MUTE_ERROR: 1,
      };
      if (print) console.log(print + '【埋点参数】：', options);
      if (process.client) {
        this.$getPoint(options)?.then((res) => {
          // if(res.errcode === 0) {
          //   // console.log('这是埋点打印出来的:', res.msg);
          // }else {
          //   // console.log('埋点报错:', res.msg);
          // }
        });
      }
    },
    /**
     * 跳转商品详情的公共方法
     * 1）缓存商品的主图或价格最低sku的图片；2）再跳转详情页
     * @author hukeyi
     * @description 为了实现商品详情页主图瞬时出现无需过渡图片的效果
     * @param { String } itemId 商品id
     * @param { String } imgUrl 商品主图url
     * @param { String } skuUrl 商品最低价格sku的图片的url
     * @param { String } target 打开页面的方式，'_self' 当前窗口打开；'_blank' 新窗口打开（默认）
     * @param { String } publish_language_id  区分是什么商品 2-中文商品  3-英文商品 国内分销商默认为中文商品，海外分销商默认为英文商品
     */
    toCachedShopdetail(
      itemId,
      imgUrl,
      skuUrl,
      target = '_blank',
      publish_language_id = '3'
    ) {
      if (!itemId || !imgUrl) return;

      let hasCached = this.$store.getters.hasGoodMainImg;
      // 如果当前商品无缓存或者当前 skuUrl 有传值，才缓存商品主图
      if (!hasCached || skuUrl) {
        // 如果没有拼接地址，则拼上
        if (!imgUrl.startsWith('http')) {
          imgUrl = process.env.NUXT_ENV.VUE_APP_SHOP_DOMAIN + imgUrl;
        }
        if (skuUrl && !skuUrl.startsWith('http')) {
          skuUrl = process.env.NUXT_ENV.VUE_APP_SHOP_DOMAIN + skuUrl;
        }
        // 首选 skuUrl；如果无，则使用商品主图 imgUrl
        let url = skuUrl ? skuUrl : imgUrl;
        // 缓存商品图片
        this.$store.dispatch('good/setGoodMainImg', { itemId, imgUrl: url });
      }
      // 跳转商品详情页
      // 给海外分销商访问国内商品的情况
      if (publish_language_id == '2') {
        // 海外分销商跳国内商品详情
        window.open(
          `${process.env.NUXT_ENV.VUE_JUMP_SHOP_Local}/${itemId}.html`,
          target
        );
      } else {
        // 海外分销商跳海外商品详情
        let routeData = this.$router.resolve({
          path: `/${itemId}.html`,
          // query: { id: itemId },
        });
        window.open(routeData.href, target);
      }
    },
    /**
     * 跳转商品详情的公共方法
     * 1）缓存商品的主图或价格最低sku的图片；2）再跳转详情页
     * @author hukeyi
     * @description 为了实现商品详情页主图瞬时出现无需过渡图片的效果
     * @param { String } itemId 商品id
     * @param { String } imgUrl 商品主图url
     * @param { String } skuUrl 商品最低价格sku的图片的url
     * @param { String } target 打开页面的方式，'_self' 当前窗口打开；'_blank' 新窗口打开（默认）
     */
    getCachedShopdetailUrl(itemId, imgUrl, skuUrl, target = '_blank') {
      if (!itemId || !imgUrl) return;

      let hasCached = this.$store.getters.hasGoodMainImg;
      // 如果当前商品无缓存或者当前 skuUrl 有传值，才缓存商品主图
      if (!hasCached || skuUrl) {
        // 如果没有拼接地址，则拼上
        if (!imgUrl.startsWith('http')) {
          imgUrl = process.env.NUXT_ENV.VUE_APP_SHOP_DOMAIN + imgUrl;
        }
        if (skuUrl && !skuUrl.startsWith('http')) {
          skuUrl = process.env.NUXT_ENV.VUE_APP_SHOP_DOMAIN + skuUrl;
        }
        // 首选 skuUrl；如果无，则使用商品主图 imgUrl
        let url = skuUrl ? skuUrl : imgUrl;
        // 缓存商品图片
        this.$store.dispatch('good/setGoodMainImg', { itemId, imgUrl: url });
      }
    },
    // 打开与客服聊天的界面
    chatWithCustomerService(target = '_blank') {
      let routeData = this.$router.resolve({
        path: '/chat/' + CS_ROUTE_NAME,
      });
      this.$store.commit('chat/clearUnread');
      window.open(routeData.href, target);
    },
    // 当前账号是否已被限制（冻结 or 关闭）
    hasAccountLimited() {
      if (process.client) {
        return (
          localStorage.getItem('is_limited_ximu_account') == 1 ||
          this.$cookies.get('is_limited_ximu_account') == 1
        );
      }
      return false;
    },
    /**
     * 获取存在七牛云上的视频的第一帧图片
     * @param { String } videoSrc 七牛云的视频链接
     */
    getVideoPoster(videoSrc) {
      if (!videoSrc) return '';
      // 如果没有拼前缀，则拼上
      if (!videoSrc.startsWith('http')) {
        videoSrc = process.env.NUXT_ENV.VUE_APP_SHOP_DOMAIN + videoSrc;
      }
      return videoSrc + '?vframe/jpg/offset/1';
    },
    //打开新页签展示
    /**
     *
     * @param {Object||String} option 路由信息对象(可自行配置Object(path,params,query),String(url))
     */
    openNewWindow(option = {}) {
      if (option || Object.keys(option).length) {
        let routeUrl = this.$router.resolve(option);
        window.open(routeUrl.href, '_blank');
      } else {
        this.$message.warning('信息传入有误!');
      }
    },
    //完善中回调
    beImprovingFunc() {
      this.$message({ type: 'warning', message: '敬请期待!' });
    },
    /**
     * 表头样式 12px
     */
    headerStyle12() {
      return {
        background: '#F4F5F6',
        color: '#666666',
        fontWeight: '400',
        fontSize: '12px',
      };
    },
    // 表头样式5(12px字体，40px高)
    headerStyle5() {
      return {
        background: '#F4F5F6',
        color: '#666',
        fontWeight: '400',
        fontSize: '14px',
        height: '40px',
      };
    },
    // 内部跳转
    routeJump(url) {
      if (url) {
        if (url.indexOf('/') != 0) {
          url = '/' + url;
        }
      }
      return url;
    },
    /**
     * 回到页面顶部
     */
    scrollToTopPage() {
      if (!process.client) {
        return;
      }
      document.documentElement.scrollTop = document.body.scrollTop = 0;
    },

    //获取当前时间并计算时间差
    getNowTime(time) {
      var date3 = time;
      if (date3 <= 0) {
        // clearInterval(this.jsq)
        // this.jsq = ''
        return '超时';
      } else {
        // date3 = Math.abs(date3);
        //计算出相差天数
        var days = Math.floor(date3 / (24 * 3600 * 1000));
        //计算出小时数
        var leave1 = date3 % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
        var hours = Math.floor(leave1 / (3600 * 1000));
        //计算相差分钟数
        var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
        var minutes = Math.floor(leave2 / (60 * 1000));
        //计算相差秒数
        var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
        var seconds = Math.round(leave3 / 1000);
        let surplusTime =
          days + '天' + hours + '时' + minutes + '分' + seconds + '秒';
        return surplusTime;
      }
    },

    /*
     *保留两位小数，向下取整
     *
     */
    keepTwoDecimal(num) {
      let result = parseFloat(num);
      if (isNaN(result)) {
        return false;
      }
      result = Math.round(num * 100) / 100;
      return result;
    },
    /*
     *保留两位小数，向下取整,不足两位，用0补全
     *
     */
    keepTwoDecimalFull(num) {
      let result = parseFloat(num);
      if (isNaN(result)) {
        return false;
      }
      result = Math.round(num * 100) / 100;
      let s_x = result.toString(); // 将数字转为字符串
      let pos_decimal = s_x.indexOf('.');
      // 当整数时，pos_decimal = -1 自动补0
      if (pos_decimal < 0) {
        pos_decimal = s_x.length;
        s_x += '.';
      }
      // 当数字长度 < 小数点索引+2时，
      while (s_x.length <= pos_decimal + 2) {
        s_x += '0';
      }
      return s_x;
    },
    /**
     * 这个方法有个限制，只有用户进行筛选，切换tabs等操作的时候才触发，刷新页面的话是不管的
     * @param {Object} options 页面的筛选条件
     */
    setPageFilter(options) {
      // 获取页面筛选条件
      let pagefilterObj = getPageFilter(options);
      // 获取页面路由路径
      let routeLink = this.$route.path;
      this.$router.push({
        path: routeLink,
        query: {
          pageid: pagefilterObj.pageId,
        },
      });
      this.$store.commit(
        'page_filter_criteria/setPageFilterCriteria',
        pagefilterObj
      );
    },
    /**
     * 获取当前所在页面缓存的筛选条件
     * @returns 对应缓存的筛选条件
     */
    getPageFilterData() {
      // 获取vuex中的数据
      let pageFiler = this.$store.state.page_filter_criteria.pageFiler;
      let query = this.$route.query.pageid;
      if (pageFiler?.[query]) {
        let info = pageFiler?.[query]?.pageFilter;
        return info || null;
        // 更新开始时间
        // this.$store.commit('page_filter_criteria/upPageFilterStartTime', query?.pageid);
        // 如果缓存中有对应的页面Id
      }
    },
  },
};
Vue.mixin(mixin);
export default mixin;
