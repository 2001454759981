
import { getOrderProgressList } from '@/api/client-api/trade/orderlist.js';
import axios from 'axios';
// 工具函数
import { CUR_TOKEN_NAME } from '@/utils/cur_default_config.js';
export default {
  name: 'OrderProgressDialog',
  props: {
    // 导入id
    importId: {
      type: String,
      default: '',
    },
    // 导入状态
    importStatus: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      successCount: 0, // 成功条数
      failCount: 0, // 失败条数
      showPayBtn: true, // 是否展示去支付按钮

      // 表格配置
      tableConfig: [
        {
          label: '序号',
          prop: 'id',
          width: '83px',
          paddingClass: 'padding-wide',
        },
        {
          label: '商品编号',
          prop: 'sku_id',
          width: '165px',
          paddingClass: 'padding-wide',
        },
        {
          label: '收货人',
          prop: 'receiver_name',
          width: '109px',
          paddingClass: 'padding-wide',
        },
        {
          label: '手机号',
          prop: 'phone',
          width: '132px',
          paddingClass: 'padding-narrow',
        },
        {
          label: '下单状态',
          prop: 'statusName',
          width: '121px',
          isSlot: true,
          paddingClass: 'padding-wide',
        },
        {
          label: '失败原因',
          prop: 'fail_reason',
          width: '281px',
          isSlot: true,
          paddingClass: 'padding-wide',
        },
      ],

      // 下单状态的样式配置
      orderStatusStyleMap: {
        3: {
          label: '下单成功',
          color: '#07C160',
        },
        2: {
          label: '下单失败',
          color: '#D22316',
        },
        1: {
          label: '等待中',
          color: '#333333',
        },
      },

      tableList: [],

      // 分页器数据
      pageNum: 1,
      pageSize: 8,
      total: 0,

      searchOptions: [
        { label: '商品编号', value: 'sku_id' },
        { label: '手机号', value: 'phone' },
      ],
      searchForm: {
        receiverName: '', // 收货人
        selectType: 'sku_id', // 下拉搜索类型 默认商品编号
        inputValue: '',
      },
    };
  },
  computed: {
    // 弹窗标题
    dialogTitle() {
      return this.importStatus == '3' ? '查看下单结果' : '查看下单进度';
    },
    // 下单完成数量
    completedCount() {
      return Number(this.successCount || 0) + Number(this.failCount || 0);
    },
    // 是否展示下载失败数据按钮
    showDownloadFailBtn() {
      // 下单完成且有失败的数据才展示该按钮
      let isComplete =
        Number(this.successCount || 0) + Number(this.failCount || 0) ===
        Number(this.total || 0); // 是否下单完成
      return isComplete && Number(this.failCount || 0) > 0;
    },
  },
  methods: {
    /**
     * 工具函数
     */
    // 过滤字符串，处理掉 \t \n 等空白符号
    normalizeText(str) {
      if (typeof str !== 'string') return str;
      // 去除 \t 等符号，并处理空值
      return str.replace(/\t|\n/g, '') || '--';
    },
    /**
     * 页面触发
     */
    // 关闭弹窗
    handleClose() {
      this.$emit('close');
    },
    // 点击切换页码
    handleChangePage(page = 1) {
      this.pageNum = Number(page) || 1;
      this.fetchOrderStatusList();
    },
    // 点击去支付
    handleGoPayList() {
      window.open(`/trade?importId=${this.importId}`);
    },
    // 收货人 搜索
    handleRecevierNameSearch(key, value) {
      this.pageNum = 1;
      this.searchForm.receiverName = value;

      this.fetchOrderStatusList();
    },
    // 下拉搜索
    handleSelectedSearch() {
      this.pageNum = 1;
      if (this.searchForm.selectType) {
        this.fetchOrderStatusList();
      }
    },
    // 清空下拉搜索
    handleClearSelect() {
      this.pageNum = 1;
      this.searchForm.selectType = 'sku_id';
      this.searchForm.inputValue = '';
      this.fetchOrderStatusList();
    },
    // 改变下拉选择 清空输入框内容
    changeSelectType() {
      this.searchForm.inputValue = '';
    },
    // 点击下载失败数据
    async handleClickDownloadFailData() {
      if (process.client) {
        const formData = new FormData();
        formData.append('recordId', this.importId);
        axios({
          url: process.env.NUXT_ENV.VUE_APP_API + '/order/exportFailOrderData',
          method: 'post',
          headers: { token: this.$cookies.get(CUR_TOKEN_NAME) },
          responseType: 'blob',
          data: formData,
        }).then((res) => {
          const blob = new Blob([res.data]);
          const objectURL = window?.URL.createObjectURL(blob);
          const link = document?.createElement('a');
          link.href = objectURL;
          link.download = `下单失败_${Date.now()}.xlsx`;
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(objectURL);
        });
      }
    },

    /**
     * 请求接口
     */

    // 获取下单进度列表
    async fetchOrderStatusList() {
      try {
        const params = {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          recordId: this.importId,
        };
        // 收货人
        if (this.searchForm.receiverName) {
          params.receiver_name = this.searchForm.receiverName;
        }
        // 下拉搜索
        if (this.searchForm?.inputValue) {
          params[this.searchForm.selectType] = this.searchForm?.inputValue;
        }

        let res = await getOrderProgressList(params);
        if (res?.errcode == 0) {
          this.tableList = res.data?.list || [];
          this.total = Number(res.data?.count) || 0;
          this.successCount = Number(res.data?.successNum) || 0;
          this.failCount = Number(res.data?.failNum) || 0;
          this.importStatus = res.data?.status;
          this.showPayBtn = res.data?.isShowPay == 1;
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
  mounted() {
    this.handleChangePage();
  },
};
