
// 组件
import indexbottom from '@/components/indexbottom.vue';
import searchBox from '@/components/comme/searchBox.vue';
import ShopCategory from './components/ShopCategory';
import SupplierInfo from './components/SupplierInfo.vue'; //供应商店铺信息
import SelectedItems from '@/pages/search-list/components/SelectedItems.vue'; //销量排行
import ListSelect from '@/pages/search-list/components/ListSelect.vue'; //筛选组件

// 语言配置
import { PUBLIC_CONFIHURATION, GOODS_BOX } from './js/label_config_list_main';
import { visitsLimit } from '@/api/client-api/api.js';

import { mapGetters } from 'vuex';
// 变量
import { curDefaultLanguage } from '@/utils/cur_default_config.js'; //默认语言

export default {
  name: 'ShopList',
  components: {
    indexbottom,
    searchBox,
    ShopCategory,
    SupplierInfo,
    SelectedItems,
    ListSelect,
  },
  head() {
    return {
      title: this.supplierInfo?.name + '_品牌分销平台_全球分销平台-细目商城',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            '细目商城为您提供' +
            this.supplierInfo?.name +
            '商品分销供应链，更多同品牌商品尽在细目商城。',
        },
        {
          hid: 'Keywords',
          name: 'Keywords',
          content:
            this.supplierInfo?.name + ',品牌分销平台,全球分销平台,细目商城',
        },
      ],
      link: [{ rel: 'icon', type: 'image/x-icon', href: 'favicon.ico' }],
    };
  },
  data() {
    return {
      lang: '', //语言
      goodsSelectedL: [], //销量排行列表

      // 倒计时相关
      countdown: 3, //倒计时
      jsq: null,
      isDialog: false, //关闭弹窗

      isBlockLayout: true, //块状显示
      // 数据相关（块状）
      // tableData: [],
      // total: 0,
      pages: 1,
      pageSize: 60,

      // 筛选相关
      sortStr: '', //筛选（名称）
      sortDesc: '', //筛选（序号）
      priceMin: '', //价格筛选
      priceMax: '', //价格筛选
      timeOptions: [], //时间筛选
      checkList: [], //预测等按钮
      keyword: '', //搜索传来的值

      supplierId: '', //供应商ID
      // supplierInfo: {}, //供应商详情

      // 店铺类目相关
      shopcategoryList: [], //存储供应商分类
      shopcategoryId: '-1', //记录选中的店铺分类
      categoryTotal: 0, //类目总数
      categoryPage: 1, //类目页数

      btnFlag: false, //是否出现回到顶部的按钮
      // 埋点
      saleTopListPoint: [], // 销量排行埋点上报数据
      storeListPoint: [], // 店铺商品埋点上报数据

      // 对比
      checkBoxList: [], //选中的列表
      currSku: {}, //选中的对象
      currSkuId: '', //选中的ID
      showCompareCol: false, //控制商品对比栏

      // isOperation: false, //是否能操作
    };
  },
  // 需要seo的
  async asyncData(app) {
    let lang = app.store.state.common.currLanguage;
    let params = app.route.params;
    let isOperation = true; //是否能操作
    let supplierId = ''; //获取供应商ID
    let supplierInfo = {
      name: '',
    }; //供应商详情
    // let shopcategoryList = []; //店铺分类
    let goodsSelectedL = []; //销量排行
    let tableData = []; //店铺列表
    let total = 0; //记录总数
    try {
      if (params.supplier_id) {
        supplierId = params.supplier_id;

        const [resShopInfo, resSaleRankList, resSearch] = await Promise.all([
          // 店铺详情
          app.$getSupplierInfo({
            supplier_id: supplierId,
            API_LANG_TYPE: lang,
            MUTE_WARNING: 1,
          }),
          // 销量排行
          app.$getSupplierSaleRankList({
            supplier_id: supplierId,
            API_LANG_TYPE: lang,
            MUTE_WARNING: 1,
          }),
          // 店铺列表
          app.$search({
            page_size: 60,
            page_num: 1,
            supplier_id: supplierId,
            API_LANG_TYPE: lang,
            MUTE_WARNING: 1,
          }),
        ]);
        // 店铺详情
        if (resShopInfo && resShopInfo?.errcode == 0) {
          supplierInfo = resShopInfo?.data || {};
          // -1-未冻结 1-冻结 2-关闭
          if (supplierInfo?.is_freeze == '1') {
            isOperation = false;
          } else {
            isOperation = true;
          }
        }
        // 销量排行
        if (resSaleRankList && resSaleRankList?.errcode === 0) {
          if (resSaleRankList?.data.list) {
            goodsSelectedL = JSON.parse(
              JSON.stringify(resSaleRankList.data.list)
            );
          }
        }

        // 店铺列表
        if (resSearch && resSearch?.errcode === 0) {
          total = Number(resSearch.data.count);
          if (resSearch.data?.list) {
            let list = JSON.parse(JSON.stringify(resSearch.data.list));
            tableData = list;
          }
        }
      }
    } catch (error) {
      console.error(error);
      if (error?.status) {
        // 请求报错
        console.log('shop-list/index.vue', error?.status, error?.statusText);
      } else {
        // 代码报错
        console.log(error);
      }
    }
    return {
      supplierInfo,
      goodsSelectedL,
      tableData,
      total,
      isOperation,
    };
  },
  watch: {
    '$route.fullPath'(n, o) {
      if (n) {
        this.$router.go(0);
      }
    },
  },
  computed: {
    ...mapGetters({
      currLang: 'common/currLang',
    }),
    // 列表页公共多语言配置
    langConfig() {
      return PUBLIC_CONFIHURATION[this.lang || curDefaultLanguage];
    },
    langConfigGoodsBox() {
      return GOODS_BOX[this.lang || curDefaultLanguage];
    },
    // 当前供应商退店剩余天数
    exitStoreDays() {
      return this.supplierInfo?.exit_store_rest_days?.toString() || '0';
    },
    // 退店公式提示文案
    exitStoreTipFont() {
      if (this.lang == 'zh-cn') {
        return `${'该店铺将于' + this.exitStoreDays + '天后退出细目平台'}`;
      }
      if (this.lang == 'en') {
        return `${
          'The store will exit the platform in ' + this.exitStoreDays + ' days'
        }`;
      }
    },
  },
  beforeDestroy() {
    if (process.client) {
      window.removeEventListener('scroll', this.scrollToTop);
    }
  },
  mounted() {
    if (process.client) {
      this.visitsLimit();
      this.lang = this.currLang;
      let params = this.$route.params;
      // // 搜索值
      if (params?.keyword) {
        this.keyword = params.keyword;
      }
      // // 店铺
      if (params?.supplier_id) {
        this.supplierId = params.supplier_id;
        this.getSupplierShopInfo();
        this.getShopCategory();
        this.getSupplierSaleRank();
      } else {
        this.$router.push('/');
      }
      if (process.client) {
        window.addEventListener('scroll', this.scrollToTop);
      }

      if (
        this.$store.state.good.goodsCompareList &&
        this.$store.state.good.goodsCompareList.length === 0
      ) {
        this.showCompareCol = false;
      }
    }
  },
  methods: {
    // 页面触发
    // 访问限制接口
    visitsLimit() {
      visitsLimit().then((res) => {
        console.log(res, 'res');
      });
    },
    // ------------------------------------------------------------
    // 对比
    // 控制商品对比栏的显隐
    showGoodCompare() {
      this.showCompareCol = !this.showCompareCol;
    },
    // 点击清空对比列表
    handleClickClear() {
      this.checkBoxList = [];
      this.showCompareCol = false;
    },
    // 商品对比列表删除的时候传出来的值
    deleteList(skuId) {
      let idx = this.checkBoxList.findIndex((item) => item === skuId);
      if (idx > -1) {
        this.checkBoxList.splice(idx, 1);
      }
      if (this.checkBoxList && this.checkBoxList.length === 0) {
        this.showCompareCol = false;
      }
    },
    /**
     * 复选进商品对比
     * @param { Boolean } checkbox 是否被选中
     * @param {Object} item 选中的数据对象
     */
    handleClickCompare(checked, item) {
      this.showCompareCol = true;
      const currSku = {
        sku_id: item.sku_id,
        item_id: item.item_id,
        title: item.title,
        price: item.price,
        imgUrl: item.sku_image || item.image_default_id, // 首选sku图片，若无，则用商品主图
        mainImgUrl: item.image_default_id,
      };
      const currSkuId = item.sku_id;
      this.$nextTick(() => {
        if (!checked) {
          // 取消选中，删除
          this.$refs.XmSkuCompareColumn?.deleteSku(currSkuId);
        } else {
          // 选中，检查商品对比栏是否已满
          if (this.$store.getters.isCompareListFull) {
            this.deleteList(currSkuId); // 从 checkBoxList 中删除
            this.$refs.XmSkuCompareColumn?.showTip(
              '对比栏已满，最多选择4款商品进行对比哦！'
            );
          } else {
            this.$refs.XmSkuCompareColumn?.insertSku(currSku);
          }
        }
      });
    },
    // 倒计时（没有供应商的交互）
    countDownFun() {
      this.countdown - 1;
      this.jsq = setInterval(() => {
        this.countdown--;
        if (this.countdown === 0) {
          this.closeFunc();
        }
      }, 1000);
    },
    /**
     * @description 回到顶部
     */
    totop() {
      const that = this;
      if (!process.client) {
        return;
      }
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          that.scrollTop + ispeed;
        if (that.scrollTop === 0) {
          clearInterval(timer);
        }
      }, 20);
    },
    scrollToTop() {
      if (!process.client) {
        return;
      }
      const that = this;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      that.scrollTop = scrollTop;
      if (that.scrollTop > 900) {
        that.btnFlag = true;
      } else {
        that.btnFlag = false;
      }
    },
    // -----------------------------------------------------
    // 店铺类目相关
    // 切换店铺分类
    changeTab(n) {
      this.shopcategoryId = n;
      this.researchList();
    },
    // 点击箭头获取类目
    handlePage(n) {
      this.categoryPage = n;
      this.getShopCategory();
    },
    // 获取供应商分类
    getShopCategory() {
      const options = {
        supplier_id: this.supplierId,
        API_LANG_TYPE: this.lang,
        pageNum: this.categoryPage,
        pageSize: '4',
        MUTE_WARNING: 1,
      };
      this.$getCategoryList(options)
        .then((res) => {
          if (res?.errcode === 0) {
            if (res?.data) {
              this.shopcategoryList =
                JSON.parse(JSON.stringify(res?.data?.list || [])) || [];
              this.categoryTotal = Number(res?.data?.total || '0') || 0;
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 获取供应商商品销量排行
    getSupplierSaleRank() {
      const options = {
        supplier_id: this.supplierId,
        API_LANG_TYPE: this.lang,
        MUTE_WARNING: 1,
      };
      this.$getSupplierSaleRankList(options)
        .then((res) => {
          if (res.errcode === 0) {
            if (res.data?.list) {
              this.goodsSelectedL = JSON.parse(JSON.stringify(res.data.list));
              // 埋点上报
              let dataList = JSON.parse(JSON.stringify(res.data.list));
              this.saleTopListPoint = this.handlePointData(dataList);
              this.burialPoint(this.saleTopListPoint);
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    // 获取供应商店铺详情
    async getSupplierShopInfo() {
      try {
        const options = {
          supplier_id: this.supplierId,
          API_LANG_TYPE: this.lang,
          MUTE_WARNING: 1,
        };
        const res = await this.$getSupplierInfo(options);
        if (res.errcode === 0) {
          this.supplierInfo = res.data;
          // -1-未冻结 1-冻结 2-关闭
          if (this.supplierInfo?.is_freeze == '1') {
            this.isOperation = false;
          } else {
            this.isOperation = true;
          }
        } else if (res.errcode === 11011) {
          this.isDialog = true;
          this.countDownFun();
        }
        // getSupplierInfo(options).then((res) => {
        //   if (res.errcode === 0) {
        //     this.supplierInfo = res.data;
        //   } else {
        //     // TODO 异常跳去异常页面或者内容切换为异常组件
        //   }
        // });
      } catch (err) {
        console.error(err);
      }
    },
    //关闭弹窗
    closeFunc() {
      clearInterval(this.jsq);
      this.$router.push('/');
    },

    // 重置分页数
    researchList() {
      this.pages = 1;
      if (this.$refs.pagTools) {
        this.$refs.pagTools.changecurPage(this.pages);
      }
      this.searchList();
    },

    // 商品列表
    async searchList() {
      try {
        const options = {
          page_size: this.pageSize,
          page_num: this.pages,
          API_LANG_TYPE: this.lang,
          MUTE_WARNING: 1,
        };
        // 排序
        if (this.sortDesc == 1) {
          options[this.sortStr] = 'asc';
        } else if (this.sortDesc == -1) {
          options[this.sortStr] = 'desc';
        }
        // 价格
        if (this.priceMax != '') {
          if (this.priceMin == '') {
            options.price_min = '0';
          } else {
            options.price_min = this.priceMin;
          }
          options.price_max = this.priceMax;
        }
        // 上架时间
        if (
          this.timeOptions &&
          this.timeOptions != [] &&
          this.timeOptions != null &&
          this.timeOptions.length
        ) {
          options.shelf_time_min = this.timeOptions[0];
          options.shelf_time_max = this.timeOptions[1];
        }

        if (this.supplierId != '' && this.supplierId != null) {
          options.supplier_id = this.supplierId;
        }
        // 搜索关键字
        if (this.keyword != '') {
          options.keyword = this.keyword;
        }

        // 更多筛选
        if (this.checkList != [] && this.checkList.length > 0) {
          this.checkList.forEach((item) => {
            options[item] = '1';
          });
        }
        // 店铺分类
        switch (this.shopcategoryId) {
          case '-1':
            break;
          default:
            options.cat_id = this.shopcategoryId;
            break;
        }
        let res = await this.$search(options);
        if (res.errcode === 0) {
          this.total = Number(res.data.count);
          if (res.data?.list) {
            let list = JSON.parse(JSON.stringify(res.data.list));
            // 根据商品对比回显
            let arr = this.$store.state.good.goodsCompareList;

            this.tableData = list;
            arr.forEach((item) => {
              this.checkBoxList.push(item.sku_id);
            });
            // 埋点上报
            let dataList = JSON.parse(JSON.stringify(res.data.list));
            this.storeListPoint = this.handlePointData(dataList);
            this.burialPoint(this.storeListPoint);
          }
        }
        // this.$search(options).then((res) => {

        // });
      } catch (err) {
        console.error(err);
        this.total = 0;
      }
    },
    // 分页事件
    // 点击确定跳转
    goPageBtn(val) {
      this.pages = val;
      window.scrollTo(0, 0);
      this.searchList();
    },

    // 筛选公共函数
    sortListBefore(sortStr, sortDesc) {
      this.sortStr = sortStr;
      this.sortDesc = sortDesc;
      // 高利润
      if (sortStr == 'profit_order') {
        this.sortDesc = -1;
      }
      this.researchList();
    },

    // 价格筛选
    handlePriceSelect(priceMin, priceMax) {
      this.priceMin = priceMin;
      this.priceMax = priceMax;
      this.researchList();
    },
    // 上架时间
    handleSelectTime(time) {
      this.timeOptions = time;
      this.researchList();
    },
    // 更多筛选复选
    changecheckboxScreening(checkList) {
      this.checkList = checkList;
      this.researchList();
    },

    // 处理埋点数据
    handlePointData(dataList) {
      if (dataList) {
        for (let i = 0; i < dataList.length; i++) {
          let item = {};
          item['item_id'] = dataList[i].item_id;
          item['time_in'] = Date.now();
          item['route'] = this.getStayRoute(this.$route);
          item['operating_system'] = this.getOperatingSystem();
          item['action_type'] = 1;
          dataList[i] = item;
        }
        return dataList;
      }
    },
    // 离开页面时候埋点数据处理
    leavePagePoint() {
      let dataPointList = [...this.saleTopListPoint, ...this.storeListPoint];
      for (let i = 0; i < dataPointList.length; i++) {
        dataPointList[i].time_out = Date.now();
      }
      this.burialPoint(dataPointList);
    },
  },
};
