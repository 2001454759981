
export default {
  name: 'XimuDialog',
  data() {
    return {
      isShow: this.deleteDialogVisible,
    };
  },
  props: {
    width: {
      type: String,
      default: '480px',
    },
    content: {
      type: String,
      default: '确认删除？',
    },
    deleteDialogVisible: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'red',
    },
    btnText: {
      type: String,
      default: '确定',
    },
    // 取消按钮文字,不展示就不传
    cancelBtnText: {
      type: String,
      default: '取消',
    },
    // 是否需要遮罩层
    needCover: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    closeDeletedig() {
      this.isShow = this.deleteDialogVisible;
      this.$emit('closeDeletedig'); // 父组件绑定的事件
    },
    configDelete() {
      this.isShow = this.deleteDialogVisible;
      this.$emit('configDeletedig'); // 父组件绑定的事件
    },
  },
};
