import request from '@/utils/request.js';

// 获取平台补贴商品
export function getSubsidizedGoods(data) {
  return request({
    url: '/item/getSubsidizedGoods',
    method: 'get',
    params: data,
  });
}

/**
 * 节庆活动相关
 */
export function getActivityGoods(data) {
  return request({
    url: '/festivalActivity/getPlateItem',
    method: 'get',
    params: data,
  });
}

/**
 * 获取商城补贴商品列表
 */

export function getMallSubsidyItemList(data) {
  return request({
    url: '/item/getMallSubsidyItemList',
    method: 'get',
    apiModule: 'goods',
    params: data,
  });
}

/**
 * 获取营销活动图片信息
 */
export function getActivityImgData(data) {
  return request({
    url: '/activity/getActivePlate',
    method: 'get',
    apiModule: 'activity',
    params: data,
  });
}
