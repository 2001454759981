
import GoodFormLine from './components/GoodFormLine';
import ResourceItem from './components/ResourceItem';
// import { addFav, deleteFav } from '@/api/shopindex';
import { zip } from './js/zip';
import { FORM_ASIDE_LABELS } from './js/label_config_form_aside';
import axios from 'axios';
import InitiateComplaints from '@/pages/back-stage/complaints-module/complaints-report/components/InitiateComplaints.vue'; //发起投诉&举报
import { COMPLAINT_SCENARIO } from '@/pages/back-stage/complaints-module/complaints-report/js/complaint_type.js'; //发起场景
// 变量
import {
  curDefaultLanguage,
  CUR_TOKEN_NAME,
} from '@/utils/cur_default_config.js'; //默认语言
export default {
  name: 'DetailFormAside',
  components: { GoodFormLine, ResourceItem, InitiateComplaints },
  props: {
    // 页面语言
    lang: {
      type: String,
      default: curDefaultLanguage,
    },
    // 商品id
    itemId: {
      type: String,
      default: '',
    },
    // 商品基础信息
    goodInfo: {
      type: Object,
      default: () => {},
    },
    // 轮播图要显示的图片列表
    previewImage: {
      type: Array,
      default: () => [],
    },
    // 是否收藏商品，初始值
    isFollowed: {
      type: Boolean,
      default: false,
    },
    // 资料 - 中文资料压缩包，目前仅支持 zip
    cnResource: {
      type: String,
      default: '',
    },
    // 资料 - 英文资料压缩包，目前仅支持 zip
    enResource: {
      type: String,
      default: '',
    },
    // 最低零售价资料
    lowestPriceFiles: {
      type: Array,
      default: () => [],
    },
    prohibitionPlatform: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      COMPLAINT_SCENARIO,
      showComplaintsDialog: false,
      // 当前是否收藏该商品
      followStatus: this.isFollowed,
    };
  },
  watch: {
    isFollowed: {
      handler: function (newVal, oldVal) {
        this.followStatus = newVal;
      },
      immediate: true,
    },
  },
  computed: {
    // 标签多语言配置
    labelConfig() {
      return FORM_ASIDE_LABELS[this.lang || curDefaultLanguage];
    },
    // 是否显示【资料】
    showMaterial() {
      return this.cnResource || this.enResource;
    },
    // 是否显示【最低零售价资料】
    showLowPrice() {
      return this.lowestPriceFiles.length > 0;
    },
    // 销售政策
    salePolicy() {
      const policy = {
        isSample: this.goodInfo?.risk_rate == '1', // 样品
        isPredict: this.goodInfo?.is_accept_predict == '1', // 预测
        isLock: this.goodInfo?.is_accept_lock == '1', // 锁货
        isPrepare: this.goodInfo?.is_stockup == '2', // 备货
        isPriceControl: Number(this.goodInfo?.price_control) === 1, // 建议最低零售价
      };
      return policy;
    },
    // 样品政策
    samplePolicy() {
      const policy =
        this.goodInfo?.risk_rate == '1'
          ? this.labelConfig?.['sample-policy-content'](
              this.goodInfo.sample_policy_rate
            )
          : '--';
      return policy;
    },
    /**
     * 温馨提示
     * 供应商问题
     */
    supplierProblem() {
      if (this.goodInfo?.return_policy) {
        let problem = this.goodInfo?.return_policy?.supplier_problem || [];
        const len = problem.length;

        problem = problem.map((x, index) => (index < len - 1 ? x + '；' : x));
        return problem;
      }
      return [];
    },
    /**
     * 温馨提示
     * 分销商问题
     */
    userProblem() {
      if (this.goodInfo?.return_policy) {
        let problem = this.goodInfo?.return_policy?.user_problem || [];
        const len = problem.length;

        problem = problem.map((x, index) => (index < len - 1 ? x + '；' : x));
        return problem;
      }
      return [];
    },
  },
  methods: {
    /**
     * 页面事件
     */
    // 打开投诉&举报弹框
    handleComplaints() {
      this.showComplaintsDialog = true;
    },
    // 关闭投诉&举报弹框
    handleCloseComplaintsForm() {
      this.showComplaintsDialog = false;
    },
    // 投诉&举报弹框确认按钮
    handleConfimComplaints() {
      this.showComplaintsDialog = false;
    },

    /**
     * 更新轮播图主图
     * @param url 图片地址
     */
    handleUpdateCarouselImage(url) {
      this.$refs.goodMainImage?.handleUpdateCurrentImgByUrl(url);
    },
    /**
     * 点击对比按钮
     */
    handleClickCompare() {
      this.$emit('compare-sku');
    },
    /**
     * 资料文件预览列表弹窗
     */
    handlePreviewMaterial() {
      this.$emit('preview-material');
    },
    /**
     * 最低零售价资料预览列表弹窗
     */
    handlePreviewLowPrice() {
      this.$emit('preview-low-price-files');
    },
    /**
     * 库存资料预览
     */
    handlePreviewStockFile() {
      this.$emit('preview-stock-file');
    },
    /**
     * 资料下载
     */
    handleDownloadMaterial() {
      this.$emit('download-material');
    },
    /**
     * 库存资料下载
     */
    handleDownloadStockFile() {
      let itemId = this.itemId;
      let token = this.$cookies.get(CUR_TOKEN_NAME);

      let url = `/shopapi/item/exportInventoryResource?item_id=${itemId}&token=${token}`;
      this.downloadFile(url, `库存资料_${this.itemId}_${Date.now()}`);
    },
    /**
     * 最低零售价下载压缩包
     */
    handleDownloadLowPrice() {
      let zipName = `最低零售价资料_${this.itemId}_${Date.now()}`;
      zip(this.lowestPriceFiles, zipName);
    },

    /**
     * 请求接口
     */

    /**
     * 收藏商品
     */
    async handleFollow() {
      try {
        const postData = { item_id: this.itemId };
        const res = await this.$addFav(postData);
        if (res.errcode == 0) {
          this.followStatus = !this.followStatus;
          this.$message.success('收藏成功');
        }
      } catch (err) {
        console.error(err);
      }
    },
    /**
     * 取消收藏商品
     */
    async handleUnfollow() {
      try {
        const postData = { item_id: this.itemId };
        const res = await this.$deleteFav(postData);
        if (res.errcode == 0) {
          this.followStatus = !this.followStatus;
          this.$message.success('已取消收藏');
        }
      } catch (err) {
        console.error(err);
      }
    },

    /**
     * 下载文件名
     * @param url 下载链接
     * @param name 文件名
     */
    downloadFile(url, name) {
      axios({
        url: url,
        method: 'get',
        responseType: 'blob',
      })
        .then((res) => {
          const filestream = res.data;
          const blob = new Blob([filestream], {
            type: res.headers['content-type'],
          });
          if (!process.client) {
            return;
          }
          // 创建下载连接
          const a = document.createElement('a');
          const href = window.URL.createObjectURL(blob);
          a.href = href;
          a.download = decodeURI(name);
          document.body.appendChild(a);
          a.click();
          // 下载完移除元素
          document.body.removeChild(a);
          window.URL.revokeObjectURL(href);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 去授权
    goAuthorization() {
      window.open('/mine/authorizationmanage', '_blank');
    },
  },
};
