import { render, staticRenderFns } from "./Platform.vue?vue&type=template&id=30283ad5&scoped=true"
import script from "./Platform.vue?vue&type=script&lang=js"
export * from "./Platform.vue?vue&type=script&lang=js"
import style0 from "./Platform.vue?vue&type=style&index=0&id=30283ad5&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30283ad5",
  null
  
)

export default component.exports