
export default {
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleCheck(val) {
      this.$emit('update:checked', val);
    },
    
    handleChange(val) {
      this.$emit('update:showProtocolTip', !val);
    },
  },
};
