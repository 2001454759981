
import { unzip } from '../js/unzip';
import JSZip from 'jszip';
import { COMMON_LABELS } from '../js/label_config_common';
import axios from 'axios';
import {
  previewImageZip,
  previewPdfZip,
  previewWordZip,
  previewOfficeZip,
  previewTxtZip,
} from '../js/preview-zip-files';
import {
  previewImageUrl,
  previewPdfUrl,
  previewWordUrl,
  previewExcelUrl,
  previewOffice,
  previewTxtUrl,
} from '../js/preview-url-files';
// 变量
import { curDefaultLanguage } from '@/utils/cur_default_config.js'; //默认语言
export default {
  name: 'FilesPreviewDialog',
  props: {
    // 文件列表预览弹窗类型
    // 1）单独一个文件列表的（传 files）=> normal
    // 2）两个中英文压缩包的（传 cnZipFile 和 enZipFile）=> multi
    type: {
      type: String,
      default: 'normal',
    },
    // 页面语言
    lang: {
      type: String,
      default: curDefaultLanguage,
    },
    // 是否展示中英文tabs
    showLang: {
      type: Boolean,
      default: false,
    },
    // 中文资源  .zip
    cnZipFile: {
      type: String,
      default: '',
    },
    // 英文资源 .zip
    enZipFile: {
      type: String,
      default: '',
    },
    // 资源名称
    files: {
      type: Array,
      default: () => [],
    },
    containerId: {
      type: String,
      default: 'preview-container',
    },
  },
  data() {
    return {
      language: 'Chinese',
      currZipFile: '', // 当前文件
      fileList: this.files.slice(),
      cnFileList: [],
      enFileList: [],
      cnZipTool: null,
      enZipTool: null,
      currZipTool: null,
      cnTip: '暂无数据',
      enTip: 'No data',
      fileImgMap: {
        //各种文件类型所展示的封面图
        pdf: require('@/assets/material/pdf.svg'),
        excel: require('@/assets/material/excel.svg'),
        word: require('@/assets/material/word.svg'),
        zip: require('@/assets/material/zip.svg'),
      },
    };
  },
  computed: {
    labelConfig() {
      return COMMON_LABELS[this.lang || curDefaultLanguage];
    },
  },
  methods: {
    // 切换中英文
    handleClickTabs() {
      if (this.language === 'Chinese') {
        this.currZipFile = this.cnZipFile;
        this.currZipTool = this.cnZipTool;
        this.fileList = this.cnFileList;
      } else if (this.language === 'English') {
        this.currZipFile = this.enZipFile;
        this.currZipTool = this.enZipTool;
        this.fileList = this.enFileList;
      }
    },
    handleClose() {
      this.$emit('close-dialog');
    },

    /**
     * 压缩包
     */
    /**
     * 格式化文件列表数据结构
     */
    formatFileList(list, zipTool) {
      const files = list.map((x) => {
        const fileInfo = Object.assign({ fileImg: '', fileUrl: '' }, x);
        let type = '';
        let suffix = fileInfo.fileName.split('.').pop();

        switch (suffix) {
          case 'jpg':
          case 'jpeg':
          case 'png':
            type = 'image';
            break;
          case 'pdf':
            type = 'pdf';
            break;
          case 'doc':
            type = 'office';
            break;
          case 'docx':
            type = 'word';
            break;
          case 'xls':
          case 'xlsx':
            type = 'excel';
            break;
          case 'txt':
            type = 'txt';
            break;
          default:
            break;
        }
        fileInfo.fileType = type;
        fileInfo.fileImg = this.fileImgMap[type];
        if (suffix === 'doc' || suffix === 'docx') {
          fileInfo.fileImg = this.fileImgMap['word'];
        }
        if (fileInfo.fileType === 'image') {
          fileInfo.fileImg = fileInfo.fileUrl;
          // 生成 data-url，实现图片缩略图预览
          if (!fileInfo.fileUrl && zipTool) {
            zipTool
              .file(fileInfo.filePath)
              .async('blob')
              .then((blob) => {
                let url = window.URL.createObjectURL(blob);
                fileInfo.fileImg = fileInfo.fileUrl = url;
              })
              .catch((err) => {
                console.error(err);
              });
          }
        }
        return fileInfo;
      });
      return files;
    },
    /**
     * 解压缩zip文件
     * @param url zip文件路径
     * @param langType 文件的语言类型
     */
    unzipFile(url, langType) {
      return new Promise(async (resolve, reject) => {
        let zipToolProp = langType + 'ZipTool';
        try {
          let zipTool = (this.$data[zipToolProp] = new JSZip());
          const res = await unzip(zipTool, url);
          return resolve({ list: res, status: true, langType });
        } catch (err) {
          console.error(err);
          // 为什么 catch 到错误后，用 resolve() 返回？
          // 因为如果抛出 reject，当中英文资料均存在时，
          // 中文压缩包解压失败将堵塞整个进程
          // 不会执行英文压缩包的 unzipFile 操作
          // 所以用 resolve(Boolean) 来标识解压缩结果的成功或失败
          return resolve({ list: [], status: false, langType });
        }
      });
    },
    /**
     * 设置压缩包解压后的文件数据
     * @param { Boolean } isSuccess 解压是否成功
     * @param { String } 资料语言类型 cn - 中文；en - 英文
     * @param { Array } 解压后的文件列表
     */
    setZipFileList(isSuccess, langType, list) {
      let fileListProp = langType + 'FileList',
        zipToolProp = langType + 'ZipTool',
        tipProp = langType + 'Tip';
      if (isSuccess) {
        // 如果成功解压
        this.$data[fileListProp] = this.formatFileList(
          list,
          this.$data[zipToolProp]
        );
        this.$data[tipProp] =
          this.$data[fileListProp].length > 0 ? '解压成功' : '暂无数据';
      } else {
        // 如果解压失败
        this.$data[tipProp] = '解压缩失败';
      }
    },
    getZipImageList() {
      const imgs = this.fileList.filter((x) => x.fileType === 'image');
      return imgs;
    },
    getUrlImageList() {
      const imgs = this.fileList.filter((x) => x.fileType === 'image');
      return imgs;
    },
    handlePreviewZipFile(file) {
      if (!process.client) {
        return;
      }
      let type = file.fileType;
      let eventName = type === 'image' ? 'preview-image' : 'preview';
      let param = '';
      if (type === 'image') {
        const imageList = this.getZipImageList();
        const imageIndex = imageList.findIndex(
          (x) => x.filePath === file.filePath
        );
        param = {
          imgs: imageList,
          zip: this.currZipTool,
          imgIndex: imageIndex,
        };
      } else {
        param = type;
      }
      let containerId =
        type === 'image' ? 'preview-image-container' : this.containerId;
      if (eventName === 'preview-image') {
        this.$emit(eventName, param);
      } else {
        this.$emit(eventName, true, param);
      }
      this.$nextTick(() => {
        let zipTool = this.currZipTool;
        let previewDom = document.getElementById(containerId);
        previewDom.innerHTML = '';

        let path = file.filePath;
        switch (type) {
          case 'image':
            // previewImageZip(zipTool, path, 'preview-image-container');
            break;

          case 'pdf':
            previewPdfZip(zipTool, path, containerId);
            break;

          case 'word':
            previewWordZip(zipTool, path, containerId);
            break;

          case 'office':
          case 'excel':
            previewOfficeZip(zipTool, path, containerId);
            break;

          case 'txt':
            previewTxtZip(zipTool, path, containerId);
            break;

          default:
            this.$alert('暂时不支持预览此种类型的文件');
            this.$emit('preview', false);
            break;
        }
      });
    },
    async handlePreviewUrlFile(file) {
      if (!process.client) {
        return;
      }
      if (file.fileType === 'image') {
        const imageList = this.getUrlImageList();
        const imageIndex = imageList.findIndex(
          (x) => x.fileUrl === file.fileUrl
        );
        let param = {
          imgs: imageList,
          imgIndex: imageIndex,
        };
        this.$emit('preview-image', param);
        return;
      }
      this.$emit('preview', true, file.fileType);
      this.$nextTick(() => {
        let containerId = this.containerId;
        let previewDom = document.getElementById(containerId);
        previewDom.innerHTML = '';
        let url = file.fileUrl,
          type = file.fileType;
        switch (type) {
          case 'image':
            previewImageUrl(url, containerId);
            break;

          case 'pdf':
            previewPdfUrl(url, containerId);
            break;

          case 'word':
            previewWordUrl(url, containerId);
            break;

          case 'office':
          case 'excel':
            previewOffice(url, containerId);
            break;

          case 'txt':
            previewTxtUrl(url, containerId);
            break;

          default:
            this.$alert('暂时不支持预览此种类型的文件');
            this.$emit('preview', false);
            break;
        }
      });
    },
    // 下载
    handleDownload(url) {
      this.$emit('download');
      // if (!this.showLang) {
      //   this.$emit('download');
      //   return;
      // }
      // if (!url) {
      //   this.$message.warning('暂无该资料包');
      //   return;
      // }
      // if (!process.client) {
      //   return;
      // }
      // let fileName = `${
      //   this.language === 'Chinese' ? '中文资料' : 'English_Materials'
      // }_${Date.now()}`;
      // axios({
      //   url: url, //下载的url
      //   method: 'get',
      //   responseType: 'blob',
      // })
      //   .then((res) => {
      //     const filestream = res.data; // 返回的文件流
      //     const blob = new Blob([filestream], {
      //       type: res.headers['content-type'],
      //     });
      //     const a = document.createElement('a');
      //     const href = window.URL.createObjectURL(blob); // 创建下载连接
      //     a.href = href;
      //     a.download = decodeURI(fileName);
      //     document.body.appendChild(a);
      //     a.click();
      //     document.body.removeChild(a); // 下载完移除元素
      //     window.URL.revokeObjectURL(href); // 释放掉blob对象
      //   })
      //   .catch((err) => {
      //     console.error('下载失败：', err);
      //   });
    },
    async unzipAll() {
      this.language = this.lang === 'en' ? 'English' : 'Chinese';
      const res = [];
      if (this.language === 'Chinese' && this.cnZipFile) {
        this.cnTip = '正在解压缩......';
        let cnFileRes = await this.unzipFile(this.cnZipFile, 'cn');
        res.push(cnFileRes);
      }
      if (this.language === 'English' && this.enZipFile) {
        this.enTip = 'Decompressing...';
        let enFileRes = await this.unzipFile(this.enZipFile, 'en');
        res.push(enFileRes);
      }
      for (let fileRes of res) {
        let { list, status, langType } = fileRes;
        this.setZipFileList(status, langType, list);
      }

      this.handleClickTabs();
    },
  },
  async mounted() {
    if (this.type === 'multi') {
      this.unzipAll();
    } else if (this.type === 'normal') {
      // 如果是非压缩包的文件预览（e.g. 逗号分隔的文件路由列表）
      if (this.fileList && this.fileList.length > 0) {
        this.fileList = this.formatFileList(this.fileList);
      }
    }
  },
};
