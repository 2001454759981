
import { bankcardPayment } from '@/api/client-api/goods/pay.js';
import BalancePayment from './BalancePayment.vue';
import NoticeDialog from '@/pages/back-stage/fund-module/fund-centre/components/NoticeDialog.vue';
import { Decimal } from 'decimal.js';
// api
import { jumpPayment, weiPayStatus } from '@/api/client-api/goods/pay.js';
import { getBasicInformation } from '@/api/client-api/account/accountmanage.js';
import { getUserDetailInfo } from '@/api/client-api/account/information.js';
import { signAgreement } from '@/api/client-api/fund/fundCenter.js';
export default {
  name: 'DollerPayMethod',
  components: {
    BalancePayment,
    NoticeDialog,
  },
  data() {
    return {
      currentMethod: '2', //当前选中的支付方式
      disablePay: false, // 禁止点击立即支付

      // 弹框相关
      // 余额支付相关
      balanceShow: false, // 余额支付弹框
      hasPayPassword: false, // 是否设置了支付密码
      noPayPasswordDialog: false, // 没有设置支付密码弹窗状态
      showNoticeDialog: false, // 用户充值告知书弹窗
      isSignRecharge: '', // 是否签署账户余额充值、使用与提现协议
      // 支付成功弹窗相关
      showSuccessDialog: false, // 提交成功弹窗
      countSecond: 3, // 倒计时秒
      timer: null, // 计时器
      // 银行卡支付相关
      showPayDialog: false,
      orderId: this.$store.state.pay.choseOrdersId.toString(), // 订单号
      imgData: [], // 转账记录
      // 微信支付相关
      weChatShow: false,
      payTime: 60, // 1分钟
      stateTimer: null, // 获取微信支付状态
      codeUrl: '', //二维码链接
      out_trade_no: '', //微信验证信息

      cannotPayTipDialog: false, // 不可支付提示弹窗状态
      errTip: '', // 错误提示文字
    };
  },
  props: {
    // 平台端银行卡信息 以及付款金额
    payInfo: {
      type: Object,
      default: () => {},
    },
    balanceUSD: {
      type: String,
      default: '',
    },
    // 支付方式
    USDPay: {
      type: Object,
      default: null,
    },
    // 订单号
    ordersNumber: {
      type: String,
      default: '',
    },
  },

  filters: {
    // 判断是否有值
    emptyValFilter(val) {
      return !val ? '--' : val;
    },
  },

  computed: {
    currLanguage() {
      return this.$store.state.common.currLanguage;
    },

    // 获取当前支付方式
    payMethods() {
      const payArr = this.USDPay?.third_payment || [];
      // 2-余额支付  3-微信支付（换汇） 6-支付宝支付（换汇）
      const payType = ['2', '3', '6']; //后端定的
      // 支付方式只出现payType中的
      const curPayArr = payArr?.filter((item) => payType?.includes(item.id));
      return curPayArr;
    },

    // 计算余额减去支付之后的金额
    payAfterBalance() {
      let afterBalance = new Decimal(Number(this.balanceUSD))
        .sub(Number(this.payInfo.priceUSD))
        .toFixed(2);
      //#TODO 为-1的原因是，为0可以继续用余额支付，为-1的话，就要去充值
      // 这个做法都有问题，但是这是异常处理的办法之一，暂时这么做,如果有更好的办法再改
      return isNaN(afterBalance) ? -1 : afterBalance;
    },

    // 是否展示剩余余额 2-余额支付
    hasPayAfterBalance() {
      return this.currentMethod == '2';
    },
    // 是否展示换汇后的金额（#TODO 目前只有美元转人民币，所以先写死币种展示）
    // 3-微信支付（换汇） 6-支付宝支付（换汇）
    hasAfterCurrencyExchange() {
      return this.currentMethod == '3' || this.currentMethod == '6';
    },
  },

  methods: {
    /**
     * 页面触发
     */
    // 关闭 禁止点击按钮
    closeDisablePay() {
      this.disablePay = false;
    },
    clearTimer() {
      //清除定时器
      clearInterval(this.timer);
      this.timer = null;
    },
    // 倒计时
    countDown() {
      this.timer = setInterval(() => {
        //创建定时器
        if (this.countSecond === 1) {
          this.clearTimer(); //关闭定时器
          let currentRouter = this.$route.path;
          if (currentRouter !== '/trade/paymentprocessing') {
            this.$router.replace('/trade/paymentprocessing');
          }
        } else {
          this.countSecond--;
        }
      }, 1000);
    },
    // 选择支付方式
    selectPayMethod(payMethodId) {
      this.currentMethod = payMethodId;
    },
    // 点击立即支付
    async handlePayment() {
      // 支付按钮禁用时不可以再次点击，直接return
      if (this.disablePay) return;
      // 点击立即支付后禁用立即支付按钮
      this.disablePay = true;

      if (this.currentMethod == 1) {
        // 银行卡
        this.showPayDialog = true;
      } else if (this.currentMethod == 2) {
        // 余额支付
        await this.getBasicInformation();
        if (!this.hasPayPassword) {
          // 如果没支付密码的情况
          this.noPayPasswordDialog = true;
          return;
        }
        this.balanceShow = true;
      } else {
        const options = {
          3: 'aliPay',
          6: 'weChatPay',
        };
        const curFunction = options[this.currentMethod];
        if (curFunction) {
          this[curFunction]();
        }
      }

      this.$emit('fixed-rate', true);
    },

    // 关闭余额支付
    closebalance() {
      this.balanceShow = false;
      // 关闭 禁止点击按钮
      this.closeDisablePay();
    },
    // 关闭线下银行卡转账弹窗
    closePayDialog() {
      this.showPayDialog = false;
      // 关闭 禁止点击按钮
      this.closeDisablePay();
      this.$emit('fixed-rate', false);
    },
    paySuccess() {
      this.showSuccessDialog = true;
    },
    // 关闭提交成功弹窗
    closeSuccessDialog() {
      this.showSuccessDialog = false;
      // 关闭 禁止点击按钮
      this.closeDisablePay();
      this.$router.replace('/trade/paymentprocessing');
    },
    uploadImg(img) {
      this.imgData = img;
    },
    // 点击提交 确认支付
    async confirmPayment() {
      try {
        if (this.imgData?.length == 0) {
          this.$message.warning('Please upload the transfer record');
          return;
        }
        let options = {
          tid: this.orderId,
          payment_type: '11', // 支付方式  （11表示美元银行卡转账）
          payment_voucher: this.imgData.join(','),
          API_LANG_TYPE: this.currLanguage,
        };
        let res = await bankcardPayment(options);

        if (res.errcode == 0) {
          this.closePayDialog(); // 关闭银行卡转账弹窗
          this.paySuccess(); // 打开支付成功弹窗
          this.countDown(); // 打开倒计时
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 去美元充值
    goRecharge() {
      // 判断是否签署了协议，签署了直接跳转充值页面，未签署则打开告知书弹窗 isSignRecharge 1：签署，-1：未签署
      if (this.isSignRecharge == '1') {
        this.$router.push('/recharge/balance/2');
      } else {
        this.showNoticeDialog = true;
      }
    },

    // 支付相关
    /**
     * 支付密码相关
     */
    // 获取是否有支付密码
    getBasicInformation() {
      return new Promise((resolve, reject) => {
        getBasicInformation()
          .then((res) => {
            // 关闭 禁止点击按钮
            this.disablePay = false;
            if (res.errcode == 0) {
              this.hasPayPassword = res.data?.pay_password;
              resolve();
            } else {
              reject();
            }
          })
          .catch((err) => {
            // 关闭 禁止点击按钮
            this.disablePay = false;
            console.error(err);
            reject();
          });
      });
    },
    // 关闭提示没有设置支付密码弹窗
    closeNoPayPassword() {
      this.noPayPasswordDialog = false;
    },
    // 跳转去账号及认证板块
    gotoSetting() {
      this.noPayPasswordDialog = false;
      // this.openNewWindow('/account');
      this.$router.push('/account');
    },

    // 获取用户信息 是否签署了《账户余额充值、使用与提现协议》
    async getUserInfo() {
      try {
        const res = await getUserDetailInfo();
        if (res?.errcode == 0) {
          //  是否签署账户余额充值、使用与提现协议【1：是 -1：否】
          this.isSignRecharge = res.data?.is_sign_agreement;
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 签署协议(充值的时候，没签署协议的情况)
    async signAgreementPost() {
      const options = {
        id: '9',
      };
      try {
        const res = await signAgreement(options);
        if (res?.errcode == 0) {
          // 跳转充值页面
          this.$router.push('/recharge/balance/2');
        }
      } catch (err) {
        console.error(err);
      }
    },

    // 支付宝支付
    aliPay() {
      let options = {
        tid: this.ordersNumber,
        payment_type: this.currentMethod,
        is_card: 0,
      };
      jumpPayment(options)
        .then((res) => {
          if (res.errcode == 0) {
            let str = res?.data?.trim();
            if (str != '') {
              const div = document.createElement('div');
              div.innerHTML = res?.data;
              document.body.appendChild(div);
              document.forms[0].submit();
            } else {
              // 关闭 禁止点击按钮
              this.closeDisablePay();
            }
            // this.$message.warning(res.msg);
          } else {
            this.errTip = res.msg;
            this.cannotPayTipDialog = true;
            // 关闭 禁止点击按钮
            this.closeDisablePay();
          }
        })
        .catch((err) => {
          // 关闭 禁止点击按钮
          this.closeDisablePay();
          console.error(err);
        });
    },

    // 微信支付
    weChatPay() {
      let option = {
        tid: this.ordersNumber,
        payment_type: this.currentMethod,
        is_card: 0,
        MUTE_WARNING: 1,
      };
      jumpPayment(option)
        .then((res) => {
          if (res?.errcode == 0) {
            // 关闭 禁止点击按钮
            this.closeDisablePay();
            this.payTime = 60;
            this.weChatShow = true;
            this.weChatUrl = res?.data?.code_url;
            this.out_trade_no = res?.data?.out_trade_no;
            this.handleClickCreateQRcode();
            // 获取支付状态
            if (this.stateTimer) {
              clearInterval(this.stateTimer);
            }
            this.stateTimer = setInterval(this.weiPayStatus, 1000);
            // 支付倒计时
            if (this.weChatTimer) {
              clearInterval(this.weChatTimer);
            }
            this.weChatTimer = setInterval(this.payTimeNum, 1000);
          } else {
            this.errTip = res.msg;
            this.cannotPayTipDialog = true;
            // 关闭 禁止点击按钮
            this.closeDisablePay();
          }
        })
        .catch((err) => {
          // 关闭 禁止点击按钮
          this.closeDisablePay();
          console.error(err);
        });
    },
    // 获取微信支付状态
    weiPayStatus() {
      let option = {
        out_trade_no: this.out_trade_no,
        MUTE_WARNING: 1,
      };
      weiPayStatus(option)
        .then((res) => {
          if (res.errcode == 0 && res.data.status == 'SUCCESS') {
            if (this.stateTimer) {
              clearInterval(this.stateTimer);
            }
            this.$router.push({
              path: '/paysuccess',
              query: { tid: this.ordersNumber },
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 确认订单不可继续支付提示
    sureTipContent() {
      this.cannotPayTipDialog = false;
      this.$router.push('/trade/nopay');
    },

    // 点击生成二维码
    async handleClickCreateQRcode() {
      try {
        const options = {
          text: this.weChatUrl,
          version: this.version,
          margin: 0,
          MUTE_WARNING: 1,
        };
        let that = this; // 必须要，否则 this 指向会错误
        function callbackFn(err, url) {
          if (err) {
            console.error(err);
            return;
          }
          that.codeUrl = url;
        }
        await this.createQRcode(options, callbackFn);
      } catch (err) {
        console.error(err);
      }
    },
    // 1分钟倒计时
    payTimeNum() {
      if (this.payTime > 0) this.payTime--;
      if (this.payTime == 0 && this.weChatTimer) {
        clearInterval(this.weChatTimer);
      }
    },
    // 计算微信二维码失效倒计时
    getHour(time) {
      let mine = time % 60; // 分钟
      let hour = parseInt(time / 60);
      return [hour, mine];
    },

    // 关闭微信支付弹窗
    closeweChat() {
      this.weChatShow = false;
      if (this.stateTimer) {
        clearInterval(this.stateTimer);
      }
      if (this.weChatTimer) {
        clearInterval(this.weChatTimer);
      }
    },
  },
  mounted() {
    this.getUserInfo();
  },
  beforeDestroy() {
    // 离开页面清除计时器
    if (this.stateTimer) {
      clearInterval(this.stateTimer);
    }
    if (this.weChatTimer) {
      clearInterval(this.weChatTimer);
    }
  },
};
