
import inputPassword from '@/pages/back-stage/account-module/components/inputPassword.vue';
import NoticeDialog from '@/pages/back-stage/fund-module/fund-centre/components/NoticeDialog.vue';
import { jumpPayment, weiPayStatus } from '@/api/client-api/goods/pay.js';
import { getBasicInformation } from '@/api/client-api/account/accountmanage.js';
import { encryptor } from '@/utils/XmAES128Encryptor.js';
import { getUserDetailInfo } from '@/api/client-api/account/information.js';
import { signAgreement } from '@/api/client-api/fund/fundCenter.js';

export default {
  name: 'PaymentMethod',
  props: {
    // 应付总金额
    amountPayable: {
      type: [Number, String],
      default: 0,
    },
    // 订单号
    ordersNumber: {
      type: String,
      default: '',
    },
    // 余额
    balanceValue: {
      type: String,
      default: '',
    },
  },
  components: {
    inputPassword,
    NoticeDialog,
  },
  data() {
    return {
      payMentMethod: 3, // 目前1是微信, 2是支付宝，3是余额支付
      // orderNum: '', // 订单编号

      // 微信支付相关
      weChatTimer: null, // 微信支付倒计时
      weChatShow: false,
      version: 6, // 二维码生成的版本，数字越大，二维码越复杂
      out_trade_no: '',
      stateTimer: null, // 获取微信支付状态
      payTime: 1800, // 30分钟

      cannotPayTipDialog: false, // 不可支付提示弹窗状态
      errTip: '', // 错误提示文字
      cancelOrder: false, // 是否是取消订单,true关闭不可支付提示弹窗跳转订单列表

      passwordShow: false, // 余额支付
      passWord: '', // 余额支付密码
      passWorldTip: 1, // 余额支付错误提示语，1不展示错误提示，2展示密码位数少，3展示业务提示错误
      passErrTip: '',

      hasPayPassword: false, // 是否设置了支付密码
      noPayPasswordDialog: false, // 没有设置支付密码弹窗状态
      disablePay: false, // 禁止点击立即支付
      disableConfirmPay: false, // 余额支付弹窗 确认支付按钮

      showNoticeDialog: false, // 用户充值告知书弹窗
      isSignRecharge: '', // 是否签署账户余额充值、使用与提现协议
    };
  },
  computed: {
    payAfterBalance() {
      return this.keepTwoDecimalFull(
        (Number(this.balanceValue) * 1000 - Number(this.amountPayable) * 1000) /
          1000
      );
    },
  },
  mounted() {
    // // 获取 paymentMethod 元素
    // const paymentMethod = document.getElementsByClassName('payment-method')[0];
    // // 获取内容实际高度，并将其赋值给 --height 变量
    // const contentHeight = paymentMethod.scrollHeight;
    // paymentMethod.style.setProperty('--height', `${contentHeight}px`);
    // this.orderNum = this.$route.query.tid;
    // 获取订单编号
    // this.orderNum = this.$store.state.pay.choseOrdersId.toString();
    // this.getBasicInformation();
    this.getUserInfo();
  },
  beforeDestroy() {
    // 离开页面清除计时器
    if (this.stateTimer) {
      clearInterval(this.stateTimer);
    }
    if (this.weChatTimer) {
      clearInterval(this.weChatTimer);
    }
  },
  methods: {
    // 获取是否有支付密码
    getBasicInformation() {
      return new Promise((resolve, reject) => {
        getBasicInformation()
          .then((res) => {
            // 关闭 禁止点击按钮
            this.disablePay = false;
            if (res.errcode == 0) {
              this.hasPayPassword = res.data?.pay_password;
              resolve();
            } else {
              reject();
            }
          })
          .catch((err) => {
            // 关闭 禁止点击按钮
            this.disablePay = false;
            console.error(err);
            reject();
          });
      });
    },
    // 选择支付方式
    choseWay(id) {
      this.payMentMethod = id;
    },

    /**
     * 点击立即支付
     * payMentMethod 目前1是微信, 2是支付宝，3是余额支付
     */
    async immediatePay() {
      // 支付按钮禁用时不可以再次点击，直接return
      if (this.disablePay) return;
      // 点击立即支付后禁用立即支付按钮
      this.disablePay = true;
      if (this.payMentMethod == 1) {
        this.weChartPay();
      }
      if (this.payMentMethod == 2) {
        this.linkAlipayPay();
      }
      if (this.payMentMethod == 3) {
        await this.getBasicInformation();
        if (!this.hasPayPassword) {
          this.noPayPasswordDialog = true;
          return;
        }
        // 余额支付
        this.passwordShow = true;
      }
    },
    // 去充值
    recharge() {
      // 判断是否签署了协议，签署了直接跳转充值页面，未签署则打开告知书弹窗 isSignRecharge 1：签署，-1：未签署
      if (this.isSignRecharge == '1') {
        this.$router.push('/recharge/balance/1');
      } else {
        this.showNoticeDialog = true;
      }
    },
    // 跳转支付宝链接支付
    linkAlipayPay() {
      let options = {
        tid: this.ordersNumber,
        payment_type: 3,
        is_card: 0,
      };
      jumpPayment(options)
        .then((res) => {
          if (res.errcode == 0) {
            let str = res?.data?.trim();
            if (str != '') {
              const div = document.createElement('div');
              div.innerHTML = res?.data;
              document.body.appendChild(div);
              document.forms[0].submit();
            } else {
              // 关闭 禁止点击按钮
              this.disablePay = false;
            }
            // this.$message.warning(res.msg);
          } else {
            if (res.errcode == 10606) this.cancelOrder = true; // 库存不足订单取消
            this.errTip = res.msg;
            this.cannotPayTipDialog = true;
            // 关闭 禁止点击按钮
            this.disablePay = false;
          }
        })
        .catch((err) => {
          // 关闭 禁止点击按钮
          this.disablePay = false;
          console.error(err);
        });
    },

    // 点击微信支付
    weChartPay() {
      let option = {
        tid: this.ordersNumber,
        payment_type: 6,
        is_card: 0,
        MUTE_WARNING: 1,
      };
      jumpPayment(option)
        .then((res) => {
          // 关闭 禁止点击按钮
          this.disablePay = false;
          if (res?.errcode == 0) {
            this.payTime = 1800;
            this.weChatShow = true;
            this.weChatUrl = res?.data?.code_url;
            this.out_trade_no = res?.data?.out_trade_no;
            this.handleClickCreateQRcode();
            // 获取支付状态
            if (this.stateTimer) {
              clearInterval(this.stateTimer);
            }
            this.stateTimer = setInterval(this.weiPayStatus, 1000);
            // 支付倒计时
            if (this.weChatTimer) {
              clearInterval(this.weChatTimer);
            }
            this.weChatTimer = setInterval(this.payTimeNum, 1000);
          } else {
            if (res.errcode == 10606) this.cancelOrder = true; // 库存不足订单取消
            this.errTip = res.msg;
            this.cannotPayTipDialog = true;
          }
        })
        .catch((err) => {
          // 关闭 禁止点击按钮
          this.disablePay = false;
          console.error(err);
        });
    },
    // 30分钟倒计时
    payTimeNum() {
      if (this.payTime > 0) this.payTime--;
      if (this.payTime == 0 && this.weChatTimer) {
        clearInterval(this.weChatTimer);
      }
    },
    // 获取微信支付状态
    weiPayStatus() {
      let option = {
        out_trade_no: this.out_trade_no,
        MUTE_WARNING: 1,
      };
      weiPayStatus(option)
        .then((res) => {
          if (res.errcode == 0 && res.data.status == 'SUCCESS') {
            if (this.stateTimer) {
              clearInterval(this.stateTimer);
            }
            this.$router.push({
              path: '/paysuccess',
              query: { tid: this.ordersNumber },
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 点击生成二维码
    async handleClickCreateQRcode() {
      try {
        const options = {
          text: this.weChatUrl,
          version: this.version,
          margin: 0,
          MUTE_WARNING: 1,
        };
        let that = this; // 必须要，否则 this 指向会错误
        function callbackFn(err, url) {
          if (err) {
            console.error(err);
            return;
          }
          that.codeUrl = url;
        }
        await this.createQRcode(options, callbackFn);
      } catch (err) {
        console.error(err);
      }
    },
    // 计算微信二维码失效倒计时
    getHour(time) {
      let mine = time % 60; // 分钟
      let hour = parseInt(time / 60);
      return [hour, mine];
    },
    // 关闭微信支付弹窗
    closeweChat() {
      this.weChatShow = false;
      if (this.stateTimer) {
        clearInterval(this.stateTimer);
      }
      if (this.weChatTimer) {
        clearInterval(this.weChatTimer);
      }
    },
    // 确认订单不可继续支付提示
    sureTipContent() {
      this.cannotPayTipDialog = false;
      // 是取消订单，返回订单列表，否则返回待支付列表
      if (this.cancelOrder) {
        this.$router.push('/trade');
      } else {
        this.$router.push('/trade/nopay');
      }
    },
    // 获取余额支付密码
    getbankpass(item) {
      this.passWord = item;
    },
    // 设置密码
    setPassWord() {
      this.$router.push('/account');
    },
    // 确认余额支付
    balancePay() {
      if (this.passWord.length < 6) {
        this.passWorldTip = 2;
        return;
      }
      let option = {
        tid: this.ordersNumber,
        payment_type: 1,
        password: encryptor.encrypt(this.passWord),
        MUTE_WARNING: 1,
      };
      if (this.disableConfirmPay) return;
      this.disableConfirmPay = true;
      jumpPayment(option)
        .then((res) => {
          // 余额支付弹窗 立即支付按钮 关闭禁止
          this.disableConfirmPay = false;
          if (res.errcode == 0) {
            this.passWorldTip = 1;
            this.passwordShow = false;
            if (res?.msg) {
              this.$message.success(res?.msg);
            }

            this.$router.push({
              path: '/paysuccess',
              query: { tid: this.ordersNumber },
            });
          } else if (res.errcode == 1004) {
            this.passWorldTip = 3;
            this.passErrTip = `支付密码有误，您还可以输入${
              5 - res.data.num
            }次，${5 - res.data.num}次错误后，密码将被锁定${res.data.time}分钟`;
          } else if (res.errcode == 1001) {
            this.passWorldTip = 3;
            this.passErrTip = res.msg;
          } else if (res.errcode == 1002) {
            this.passWorldTip = 3;
            this.passErrTip = res.msg;
          } else if (res.errcode == 1003) {
            this.passWorldTip = 3;
            this.passErrTip = res.msg;
          }
          // 库存不足订单取消
          else if (res.errcode == 10606) {
            if (res?.msg) this.$message.warning(res?.msg);
            this.$router.push('/trade');
          } else {
            if (res?.msg) {
              this.$message.warning(res?.msg);
            }
          }
        })
        .catch((err) => {
          // 余额支付弹窗 立即支付按钮 关闭禁止
          this.disableConfirmPay = false;
          console.error(err);
        });
    },
    // 取消余额支付
    closeBalance() {
      this.passwordShow = false;
      this.passWorldTip = 1;
    },
    tiphide(item) {
      console.log(item, '22222');
    },
    // 关闭提示没有设置支付密码弹窗
    closeNoPayPassword() {
      this.noPayPasswordDialog = false;
    },
    // 跳转去账号及认证板块
    gotoSetting() {
      this.noPayPasswordDialog = false;
      // this.openNewWindow('/account');
      this.$router.push('/account');
    },

    // 获取用户信息 是否签署了《账户余额充值、使用与提现协议》
    async getUserInfo() {
      try {
        const res = await getUserDetailInfo();
        if (res?.errcode == 0) {
          //  是否签署账户余额充值、使用与提现协议【1：是 -1：否】
          this.isSignRecharge = res.data?.is_sign_agreement;
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 签署协议
    async signAgreementPost() {
      const options = {
        id: '9',
      };
      try {
        const res = await signAgreement(options);
        if (res?.errcode == 0) {
          // 跳转充值页面
          this.$router.push('/recharge/balance/1');
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
};
