
import {
  rechargeTrans,
  rechargeFund,
} from '@/api/client-api/fund/fundCenter.js';
import { getBasicInformation } from '@/api/client-api/account/accountmanage.js';
import bankCardTransfer from './bankCardTransfer.vue';
import { weiPayStatus } from '@/api/client-api/goods/pay.js';
import { Decimal } from 'decimal.js'; // 计算精度
import { encryptor } from '@/utils/XmAES128Encryptor.js'; // 加密
import { BALANCE_PAYMENT } from '@/pages/goods-pay/js/label_config_public'; // 多语言配置
// 组件
import NoPasswordDialog from '@/pages/goods-pay/components/NoPasswordDialog.vue'; // 没有设置过支付密码提示弹窗
import BalancePayDialog from '@/pages/goods-pay/components/BalancePayDialog.vue'; // 余额支付密码弹窗
export default {
  components: {
    bankCardTransfer,
    NoPasswordDialog,
    BalancePayDialog,
  },
  props: {
    // 充值金额
    amount: {
      type: [Number, String],
      default: 0,
    },
    // 充值类型 1余额 2运费保证金 3活动保证金 4分销保证金
    target_type: {
      type: String,
      default: '1',
    },
    // 当前余额
    currentBalance: {
      type: [Number, String],
      default: 0,
    },
    // 支付方式选择
    payTypeList: {
      type: Array,
      default: () => [],
    },
    // 语言
    lang: {
      type: String,
      default: 'zh-cn',
    },
  },
  data() {
    return {
      payMentMethod: '',
      weChatShow: false,
      payTime: 1800, // 30分钟
      codeUrl: '', // 微信二维码
      backCardShow: false,
      comshow: false, // 提交成功的温馨提示弹窗状态
      weChatUrl: '', // 微信支付
      out_trade_no: '',
      version: 6, // 二维码生成的版本，数字越大，二维码越复杂
      stateTimer: null, // 支付状态定时器
      weChatTimer: null, // 支付倒计时定时器
      // currentBalance: 0, // 当前余额
      currency: '1', //账户类型 1-人民币 2-美元
      type: '', // 类型
      disablePay: false, // 禁止点击立即支付
      disableConfirmPay: false, // 余额支付弹窗 确认支付按钮

      hasPayPassword: false, // 是否设置了支付密码
      noPayPasswordDialog: false, // 没有支付密码提示弹窗
      balancePayDialog: false, // 余额支付弹窗
      canClickBalancePay: true, // 余额支付密码弹窗提交支付密码
      balanceErrTip: '', // 余额支付错误提示
      balanceErrType: '1', // 余额支付错误提示语类型，1不展示错误提示，2展示密码位数少，3展示业务提示错误
    };
  },
  computed: {
    // 充值底部文字
    afterPayFont() {
      if (this.target_type == '1') {
        return '充值后余额：';
      } else return '支付后可用余额：';
    },
    // 充值后余额
    afterPayShow() {
      let overBanlance;
      if (this.target_type == '1') {
        overBanlance = new Decimal(this.currentBalance || 0)
          .add(new Decimal(this.amount || 0))
          .toFixed(2);
      } else {
        overBanlance = new Decimal(this.currentBalance || 0)
          .minus(new Decimal(this.amount || 0))
          .toFixed(2);
      }
      return overBanlance;
    },
    // 充值金额保留2位小数
    amountTwoDecimal() {
      if (!this.amount) return '';
      return Number(this.amount)?.toFixed(2);
    },
    // 标签多语言配置
    langConfig() {
      return BALANCE_PAYMENT[this.lang || 'zh-cn'];
    },
    // 判断当前保证金是否可以使用余额充值
    judgeBanlanceRechargeDeposit() {
      if (
        this.target_type != '1' &&
        this.payMentMethod == '1' &&
        this.afterPayShow < 0
      ) {
        return false;
      } else return true;
    },
  },
  mounted() {
    this.currency = this.$route.params.currency;

    this.type = this.$route.params.type;
    // this.getCapitalInfo();
  },
  beforeDestroy() {
    if (this.stateTimer) {
      clearInterval(this.stateTimer);
      this.stateTimer = null;
    }
    if (this.weChatTimer) {
      clearInterval(this.weChatTimer);
      this.weChatTimer = null;
    }
  },
  methods: {
    // 立即支付
    payment() {
      // 充值保证金 && 余额支付 && 充值后余额小于0不可点击
      if (!this.judgeBanlanceRechargeDeposit) return;
      if (!this.payMentMethod) {
        this.$message.warning('请选择充值方式');
        return;
      }
      if (!this.amount || !Number(this.amount)) {
        this.$message.warning('请输入充值金额');
        return;
      }
      if (this.target_type == '1') {
        this.handleBanlanceRecharge();
      } else {
        this.handleDepositRecharge();
      }
    },
    // 余额充值
    handleBanlanceRecharge() {
      let option = {
        target_type: this.target_type,
        method: this.payMentMethod,
        amount: this.amount,
      };
      // 支付按钮禁用时不可以再次点击，直接return
      if (this.disablePay) return;
      // 点击立即支付后禁用立即支付按钮
      this.disablePay = true;
      switch (this.payMentMethod) {
        case 1:
          // 微信支付
          this.weixinrechargeTrans(option);
          break;
        case 2:
          // 支付宝支付
          this.alipayRechargeTrans(option);
          break;
        case 3:
          this.backCardShow = true;
          // 关闭 禁止点击按钮
          this.disablePay = false;
          // 银行卡支付
          break;
        default:
          break;
      }
    },
    // 保证金充值
    async handleDepositRecharge() {
      let option = {
        type: '29', // 充值类型：3分销保证金 TODO: 还没有其他保证金充值像这样，以后用变量
        payment_type: this.payMentMethod, // 支付方式 1余额 2余额（美元）6微信(pc) 3支付宝(pc)
        amount: this.amount, // 充值金额（不含手续费）
        pay_amount: this.amount, // 充值金额（含手续费）
      };
      // 支付按钮禁用时不可以再次点击，直接return
      if (this.disablePay) return;
      // 点击立即支付后禁用立即支付按钮
      this.disablePay = true;
      switch (this.payMentMethod) {
        case 1:
          await this.getBasicInformation();
          if (!this.hasPayPassword) {
            this.noPayPasswordDialog = true;
            return;
          }
          // 余额支付
          this.balancePayDialog = true;
          break;
        case 6:
          // 微信支付
          this.weixinrechargeTrans(option);
          break;
        case 3:
          // 支付宝支付
          this.alipayRechargeTrans(option);
          break;
        default:
          break;
      }
    },
    // 获取是否有支付密码
    async getBasicInformation() {
      try {
        const res = await getBasicInformation({ MUTE_WARNING: 1 });
        if (res?.errcode == 0) {
          this.hasPayPassword = res?.data?.pay_password;
        }
      } catch (err) {
        console.error(err);
      }
    },
    /**
     * 余额支付提交密码
     * @param {String} password 用户输入的密码
     */
    async sureBalancePay(password) {
      try {
        if (password.length < 6) {
          this.balanceErrType = '2';
          return;
        }
        // 不能点击时return
        if (!this.canClickBalancePay) return;
        this.canClickBalancePay = false;
        let options = {
          type: '29', // 充值类型：3分销保证金 TODO: 还没有其他保证金充值像这样，以后用变量
          payment_type: this.payMentMethod, // 支付方式 1余额 2余额（美元）6微信(pc) 3支付宝(pc)
          amount: this.amount, // 充值金额（不含手续费）
          pay_amount: this.amount, // 充值金额（含手续费）
          password: encryptor.encrypt(password),
          MUTE_WARNING: 1,
        };
        const res = await rechargeFund(options);
        this.canClickBalancePay = true;
        if (res?.errcode == 0) {
          this.balanceErrType = '1';
          this.balancePayDialog = false; // 关闭余额支付弹窗
          if (res?.msg) this.$message.success(res?.msg);
          this.$router.replace({
            path: `/fund/fundcenter/${this.currency}`,
          });
        } else if (res?.errcode == 1004) {
          this.balanceErrType = '3';
          this.balanceErrTip = this.langConfig?.['err-tip'](
            5 - Number(res?.data?.num),
            res?.data?.time
          );
        } else if (
          res?.errcode == 1001 ||
          res?.errcode == 1002 ||
          res?.errcode == 1003
        ) {
          this.balanceErrType = '3';
          this.balanceErrTip = res?.msg;
        } else {
          if (res?.msg) {
            this.$message.warning(res?.msg);
          }
        }
      } catch (err) {
        this.canClickBalancePay = true; // 可以点击了
        console.error(err);
      }
    },
    // 支付宝充值
    async alipayRechargeTrans(option) {
      try {
        let res;
        if (this.target_type == '1') {
          // 余额充值
          res = await rechargeTrans(option);
        } else {
          // 保证金充值
          res = await rechargeFund(option);
        }
        if (res?.errcode == 0) {
          let str = res?.data?.trim();
          if (str != '') {
            const div = document.createElement('div');
            div.innerHTML = res?.data;
            document.body.appendChild(div);
            document.forms[0].submit();
          } else {
            // 关闭 禁止点击按钮
            this.disablePay = false;
          }
          // this.$message.warning(res.msg);
        } else {
          // 关闭 禁止点击按钮
          this.disablePay = false;
        }
      } catch (err) {
        // 关闭 禁止点击按钮
        this.disablePay = false;
        console.error(err);
      }
    },
    // 微信充值
    async weixinrechargeTrans(option) {
      try {
        let res;
        if (this.target_type == '1') {
          // 余额充值
          res = await rechargeTrans(option);
        } else {
          // 保证金充值
          res = await rechargeFund(option);
        }
        // 关闭 禁止点击按钮
        this.disablePay = false;
        if (res?.errcode == 0) {
          this.weChatShow = true;
          this.weChatUrl = res?.data?.code_url;
          this.out_trade_no = res?.data?.out_trade_no;
          this.handleClickCreateQRcode();
          if (this.stateTimer) {
            clearInterval(this.stateTimer);
            this.stateTimer = null;
          }
          this.stateTimer = setInterval(this.weiPayStatus, 1000);
          // 支付倒计时
          if (this.weChatTimer) {
            clearInterval(this.weChatTimer);
            this.weChatTimer = null;
          }
          this.weChatTimer = setInterval(this.payTimeNum, 1000);
        }
      } catch (err) {
        // 关闭 禁止点击按钮
        this.disablePay = false;
        console.error(err);
      }
    },
    // 更新微信支付状态
    weiPayStatus() {
      let option = {
        out_trade_no: this.out_trade_no,
        MUTE_WARNING: 1,
      };
      weiPayStatus(option)
        .then((res) => {
          if (res.errcode == 0 && res.data.status == 'SUCCESS') {
            if (this.stateTimer) {
              clearInterval(this.stateTimer);
            }
            this.weChatShow = false;
            this.comshow = true;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    payTimeNum() {
      if (this.payTime > 0) this.payTime--;
      if (this.payTime == 0 && this.weChatTimer) {
        clearInterval(this.weChatTimer);
        this.weChatTimer = null;
      }
      if (this.payTime == 0 && this.stateTimer) {
        clearInterval(this.stateTimer);
        this.stateTimer = null;
      }
    },
    // 点击生成二维码
    async handleClickCreateQRcode() {
      try {
        const options = {
          text: this.weChatUrl,
          version: this.version,
          margin: 0,
        };
        let that = this; // 必须要，否则 this 指向会错误
        function callbackFn(err, url) {
          if (err) {
            console.error(err);
            return;
          }
          that.codeUrl = url;
        }
        await this.createQRcode(options, callbackFn);
      } catch (err) {
        console.error(err);
      }
    },
    // 关闭银行卡支付弹框
    closeBank(type) {
      this.backCardShow = false;
      if (type == 1) {
        this.comshow = true;
      }
    },
    // 关闭微信支付弹窗
    closeweChat() {
      this.weChatShow = false;
      if (this.stateTimer) {
        clearInterval(this.stateTimer);
      }
      if (this.weChatTimer) {
        clearInterval(this.weChatTimer);
      }
    },
    // 计算微信二维码失效倒计时
    getHour(time) {
      let mine = time % 60; // 分钟
      let hour = parseInt(time / 60);
      return [hour, mine];
    },
    // 关闭弹框
    closeConfig() {
      this.$router.go(-1);
      this.comshow = false;
    },
    // 关闭弹窗
    closeDialog() {
      this.disablePay = false; // 关闭 禁止点击按钮
      this.balanceErrType = '1'; // 余额支付错误提示语类型，1不展示错误提示
      this.noPayPasswordDialog = false; // 关闭没有设置支付密码弹窗
      this.balancePayDialog = false; // 关闭余额支付密码弹窗
    },
  },
};
