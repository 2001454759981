
import invoiceTable from './invoiceTable.vue';
import replaceInvice from './replaceInvice.vue';
import {
  orderInvoiceList,
  invoiceAmount,
  getRejectReason,
} from '@/api/client-api/fund/invoice.js';
export default {
  components: { invoiceTable, replaceInvice },
  props: {
    activeName: {
      type: [Number, String],
      default: 0,
    },
  },
  computed: {
    selectSupplierID() {
      if (this.selectArr.length > 0) {
        let arr = this.selectArr.map((item) => {
          return item.supplier_id;
        });
        const set = new Set(arr);
        return [...set];
      } else {
        return [];
      }
    },
    // 总金额
    totalAmount() {
      if (this.selectArr.length > 0) {
        let allNumber = 0;
        this.selectArr.forEach((item) => {
          allNumber = allNumber + Number(item.invoice_amount);
        });
        return allNumber.toFixed(2);
      } else {
        return '0.00';
      }
    },
    tableOrderNum() {
      let orderArrID = this.selectArr.map((item) => {
        return item.tid;
      });
      const set = new Set(orderArrID);
      orderArrID = [...set];
      return orderArrID;
    },
    // 表格配置
    tableConfig() {
      return this.activeName == 1
        ? this.waitInvoicedTableConfig
        : this.auditPassTableConfig;
    },
    // 发票状态 下拉选项
    statusOptions() {
      return this.activeName == 1
        ? this.waitInvoicedStatusOptions
        : this.auditPassStatusOptions;
    },
  },
  data() {
    return {
      optionData: [
        { label: '商品名称', value: 'goods_title' },
        { label: '订单号', value: 'tid' },
      ],
      timeOptions: [
        { label: '订单完成时间', value: '1' },
        { label: '申请截止时间', value: '2' },
      ],
      searchObj: {
        state: '',
        timeKey: '', // 时间类型
        time: [],
        key: '',
        value: '',
        invoiceMaker: '', // 开票方
      },
      // 待开票订单表格配置
      waitInvoicedTableConfig: [
        {
          label: '订单号',
          prop: 'tid',
          width: '168px',
          isedit: true,
        },
        {
          label: '开票方',
          prop: 'attribution',
          width: '144px',
          isedit: true,
        },
        {
          label: '商品名称',
          prop: 'goods_title',
          width: '127px',
          isedit: true,
        },
        {
          label: '数量',
          prop: 'goods_num',
          width: '52px',
        },
        {
          label: '商品单价(￥)',
          prop: 'goods_price',
          width: '88px',
        },
        {
          label: '操作费(￥)',
          prop: 'operation',
          width: '78px',
          isedit: true,
        },
        {
          label: '到仓物流费(¥)',
          prop: 'ware_logistics',
          width: '92px',
        },
        {
          label: '快递费(￥)',
          prop: 'logistics',
          width: '78px',
          isedit: true,
        },
        {
          label: '订单完成时间',
          prop: 'finish_time',
          width: '97px',
          isedit: true,
        },
        {
          label: '申请截至时间',
          prop: 'deadline_time',
          width: '98px',
        },
        {
          label: '可开票金额(￥)',
          prop: 'invoice_amount',
          width: '100px',
          isedit: true,
        },
        {
          label: '发票状态',
          prop: 'state',
          width: '69px',
        },
        {
          label: '操作',
          prop: 'opera',
          width: '80px',
          isedit: true,
          fixed: 'right',
        },
      ],
      // 审核通过订单表格配置
      auditPassTableConfig: [
        {
          label: '订单号',
          prop: 'tid',
          width: '168px',
          isedit: true,
        },
        {
          label: '开票方',
          prop: 'attribution',
          width: '85px',
          isedit: true,
        },
        {
          label: '商品名称',
          prop: 'goods_title',
          width: '84px',
          isedit: true,
        },
        {
          label: '数量',
          prop: 'goods_num',
          width: '35px',
        },
        {
          label: '商品单价(￥)',
          prop: 'goods_price',
          width: '80px',
        },
        {
          label: '操作费(￥)',
          prop: 'operation',
          width: '67px',
          isedit: true,
        },
        {
          label: '到仓物流费(¥)',
          prop: 'ware_logistics',
          width: '92px',
        },
        {
          label: '快递费(￥)',
          prop: 'logistics',
          width: '67px',
          isedit: true,
        },
        {
          label: '可开票金额(￥)',
          prop: 'invoice_amount',
          width: '90px',
          isedit: true,
        },
        {
          label: '审核时间',
          prop: 'agree_time',
          width: '100px',
        },
        {
          label: '申请单号',
          prop: 'application_id',
          width: '100px',
        },
        {
          label: '发票状态',
          prop: 'state',
          width: '65px',
        },
        {
          label: '操作',
          prop: 'opera',
          width: '78px',
          isedit: true,
          fixed: 'right',
        },
      ],
      // 待开票订单 发票状态下拉选项
      waitInvoicedStatusOptions: [
        {
          label: '全部',
          value: '',
        },
        {
          label: '未申请',
          value: '1',
        },
        {
          label: '已驳回',
          value: '5',
        },
      ],
      // 审核通过订单 发票状态下拉选项
      auditPassStatusOptions: [
        {
          label: '全部',
          value: '',
        },
        {
          label: '待审核',
          value: '1',
        },
        {
          label: '开票中',
          value: '2',
        },
        {
          label: '已开票',
          value: '3',
        },
        {
          label: '换开待审核',
          value: '4',
        },
        {
          label: '换开中',
          value: '5',
        },
        {
          label: '换开已驳回',
          value: '8',
        },
        {
          label: '已换开',
          value: '6',
        },
      ],
      tableList: [],
      selectArr: [], // 列表选中的数据
      reasonShow: false, // 查看原因弹框
      totalnum: 0,
      tableSize: 10, // table列表
      tablePageNum: 1, // table分页
      inviceTitle: '一键开票',
      digTableLTitle: [
        {
          label: '开票方',
          prop: 'supplier_name',
          width: '150px',
        },
        {
          label: '订单数量',
          prop: 'order_num',
          width: '100px',
        },
        {
          label: '可开票金额',
          prop: 'amount',
          width: '145px',
        },
      ], // 一键开票
      digtotal: 0, // 开票数据
      inviceShow: false,
      digpageNum: 1,
      pageSize: 10,
      inviceNewShow: false, // 申请开票
      invicelist: [], // 开票信息
      orderNum: '', // 订单数量
      supplierNum: '', // 供应商数量
      allaccout: '', // 金额总计
      newInviceTid: [], // 待开票订单id
      rejectText: '', // 驳回原因
    };
  },
  methods: {
    async getInviceList() {
      try {
        this.selectArr = []; // 清除列表选中的数据
        let option = {
          type: this.activeName == 1 ? 1 : 3,
          pageNum: this.tablePageNum,
          pageSize: this.tableSize,
        };
        // 存数据
        let searchData = Object.assign(
          {
            pageNum: this.tablePageNum,
            type: this.activeName == 1 ? 1 : 3,
            activeName: this.activeName,
          },
          this.searchObj
        );
        this.setPageFilter(searchData);
        // 开票方
        if (this.searchObj.invoiceMaker) {
          option.invoice_maker = this.searchObj.invoiceMaker;
        }
        if (this.searchObj.timeKey && this.searchObj.time) {
          option.time_type = this.searchObj.timeKey;
          option.start_time = this.searchObj.time[0];
          option.end_time = this.searchObj.time[1];
        }
        if (this.searchObj.key && this.searchObj.value) {
          option[this.searchObj.key] = this.searchObj.value;
        }
        if (this.activeName == 1) {
          option.state = this.searchObj.state;
        } else if (this.activeName == 4) {
          option.apply_type = this.searchObj.state;
        }
        const res = await orderInvoiceList(option);
        if (res?.errcode == 0) {
          this.tableList = res?.data?.list;
          this.totalnum = Number(res?.data?.total) || 0;
          // 处理当前页码过大的情况
          if (this.tablePageNum > 1 && this.tableList?.length == 0) {
            this.goPage(1);
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    stateChange() {
      this.tablePageNum = 1;
      this.getInviceList();
    },
    xmSelectData(selectValue, inputValue) {
      this.searchObj.key = selectValue;
      this.searchObj.value = inputValue;
      this.getInviceList();
    },
    selectSortList(selectValue) {
      this.searchObj.timeKey = selectValue;
      this.tablePageNum = 1;
      this.getInviceList();
    },
    goPage(index, pageSize) {
      this.tablePageNum = Number(index);
      if (pageSize) {
        this.tableSize = pageSize;
      }
      this.getInviceList();
    },
    // table 框选择
    tableCheck(arr) {
      this.selectArr = arr;
    },
    // 一键开票
    invoicingAll() {
      let option = {};
      invoiceAmount(option)
        .then((res) => {
          if (res?.errcode == 0) {
            this.inviceShow = true;
            this.inviceTitle = '一键开票';
            this.invicelist = res?.data?.list;
            this.orderNum = res?.data?.order_num;
            this.supplierNum = res?.data?.supplier_num;
            this.allaccout = res?.data?.amount;
            this.newInviceTid = res?.data?.tid;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 申请开票
    async applyInvice() {
      try {
        let orderArrID = this.selectArr.map((item) => {
          return item.tid;
        });
        const set = new Set(orderArrID);
        orderArrID = [...set];
        if (orderArrID.length == 0) {
          this.$message.warning('请选择要开票的数据');
          return;
        }
        let option = {
          tid: orderArrID.join(','),
        };
        const res = await invoiceAmount(option);
        if (res?.errcode == 0) {
          this.inviceShow = true;
          this.inviceTitle = '申请开票';
          this.invicelist = res?.data?.list;
          this.orderNum = res?.data?.order_num;
          this.supplierNum = res?.data?.supplier_num;
          this.allaccout = res?.data?.amount;
          this.newInviceTid = res?.data?.tid;
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 关闭申请开票弹框
    closeInvice() {
      this.inviceShow = false;
    },
    // 确认开票
    inviceDig() {
      this.inviceNewShow = true;
    },
    // 查看原因
    viewReason(obj) {
      let option = {
        tid: obj.tid,
        drawer: obj.attribution_code,
      };
      getRejectReason(option)
        .then((res) => {
          if (res?.errcode == 0) {
            this.rejectText = res?.data?.reason;
            this.reasonShow = true;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 关闭查看原因弹框
    closeFunc() {
      this.reasonShow = false;
    },
    // 分页
    digpagechange() {},
    // 关闭一键开票
    closeReplace() {
      this.tablePageNum = 1;
      this.inviceNewShow = false;
      this.inviceShow = false;
      this.selectArr = [];
      this.getInviceList();
    },
    // 重置
    resetData() {
      this.searchObj = this.$options.data().searchObj;
      this.tablePageNum = 1;
      this.selectArr = [];
      this.$refs.XmSelect.reVal();
      this.$refs.XmDatePicker.reVal();
      this.getInviceList();
    },
    // 开票信息
    inviceingDetail(obj) {
      window.open(`/fund/invoicingInformation/${obj.application_id}`);
    },
  },
  mounted() {
    // 回显
    let searchInfo = this.getPageFilterData();
    if (searchInfo) {
      this.searchObj = JSON.parse(JSON.stringify(searchInfo));
      this.tablePageNum = searchInfo?.pageNum;
      this.$nextTick(() => {
        // 下拉搜索回显
        if (this.$refs.XmSelect) {
          this.$refs.XmSelect.reVal(this.searchObj.key, this.searchObj.value);
        }
        // 下拉选择时间筛选
        if (this.$refs.XmDatePicker) {
          this.$refs.XmDatePicker.reVal(this.searchObj?.timeKey || '');
        }
      });
    }
    this.getInviceList();
  },
};
