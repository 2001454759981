import request from '@/utils/request.js';

//资金账户(暂无使用)
export function getCapital(data) {
  return request({
    url: '/MoneyManage/capitalStatistics',
    method: 'get',
    params: data,
  });
}
//获取资金详情信息
export function getFundInfo(data) {
  return request({
    url: '/FundCenter/getFundInfo',
    method: 'get',
    apiModule: 'fund',
    params: data,
    controller: {
      openLoading: data?.openLoading || false,
    },
  });
}
// 美元银行卡转账支付平台银行卡信息
export function getUSDBankCard(data) {
  return request({
    url: '/shopTrade/getXimuBankCard',
    method: 'get',
    params: data,
    controller: {
      openLoading: data?.openLoading || false,
    },
  });
}
//充值
export function recharge(data) {
  return request({
    url: '/MoneyManage/recharge',
    method: 'get',
    params: data,
    controller: {
      openLoading: data?.openLoading || false,
    },
  });
}
//充值-最新
export function rechargeTrans(data) {
  return request({
    url: '/transaction/recharge',
    method: 'post',
    data: data,
    controller: {
      openLoading: data?.openLoading || false,
    },
  });
}
//充值（资金中心）
export function rechargeFund(data) {
  return request({
    url: '/payment/recharge',
    method: 'post',
    apiModule: 'fund',
    data: data,
    controller: {
      openLoading: data?.openLoading || false,
    },
  });
}
//查看充值详情
export function rechargeDetail(data) {
  return request({
    url: '/transaction/rechargeDetail',
    method: 'get',
    params: data,
    controller: {
      openLoading: true,
    },
  });
}
// 重新上传
export function reUpload(data) {
  return request({
    url: '/transaction/reUpload',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}
// 欠费支付
export function arrearsJumpPayment(data) {
  return request({
    url: '/fund/arrearsJumpPayment',
    method: 'post',
    data: data,
  });
}
// 欠费支付信息
export function getArrearsInfo(data) {
  return request({
    url: '/fund/getArrearsInfo',
    method: 'get',
    params: data,
  });
}
// 分销商发起提现
export function initiatingWithdrawal(data) {
  return request({
    url: '/Withdraw/initiatingWithdrawal',
    method: 'post',
    data: data,
    controller: {
      openLoading: true,
    },
  });
}
// 分销商发起提现
export function platformInfoList(data) {
  return request({
    url: '/PlatformAccredit/platformInfoList',
    method: 'get',
    params: data,
  });
}
// 签署账户余额充值、使用与提现协议
export function signAgreement(data) {
  return request({
    url: '/info/signAgreement',
    method: 'post',
    data: data,
  });
}
