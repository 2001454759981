
import friendTip from './components/friendTip.vue';
import toBeInvoiced from './components/toBeInvoiced.vue';
import applicationFormList from './components/applicationFormList.vue';
import pendingOrderreview from './components/pendingOrderreview.vue';
export default {
  name: 'invoice',
  components: {
    friendTip,
    toBeInvoiced,
    applicationFormList,
    pendingOrderreview,
  },
  data() {
    return {
      activeName: 0, // 当前选中tab栏
    };
  },
  computed: {
    currentTable() {
      switch (this.activeName) {
        // 待开票金额
        case 1:
          return 'toBeInvoiced';
        // 申请单列表
        case 2:
          return 'applicationFormList';
        // 待审核订单
        case 3:
          return 'pendingOrderreview';
        // 审核通过订单
        case 4:
          return 'toBeInvoiced';
        // 超时未开票订单
        case 5:
          return 'pendingOrderreview';
        default:
          return '';
      }
    },
  },
  mounted() {
    let searchInfo = this.getPageFilterData();
    if (this.$route.params.type) {
      this.activeName = Number(this.$route.params.type);
    } else if (searchInfo && searchInfo?.activeName) {
      this.activeName = searchInfo?.activeName;
      console.log('this.activeName', this.activeName);
    } else {
      this.activeName = 1;
    }
  },
  methods: {
    // 切换模块
    checkMoedl(index) {
      this.activeName = index;
      this.$nextTick(() => {
        this.$refs.invoiceTable.resetData();
      });
    },
  },
};
