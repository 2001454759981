
export default {
  name: 'uniqueInput',
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    // 单位
    type: {
      type: String,
      default: '',
    },
    // 默认
    placeholder: {
      type: String,
      default: '请输入',
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false,
    },
    // 是否需要单位
    isType: {
      type: Boolean,
      default: true,
    },
    // 限制输入最大字数
    maxlength: {
      type: [String, Number],
      default: '5',
    },
    // 限制输入整数
    integer: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    return {
      inputValue: this.value,
    };
  },
  watch: {
    value(newValue) {
      this.inputValue = newValue;
    },
  },
  methods: {
    focus() {
      this.$emit('focus');
    },
    blur() {
      this.$emit('blur');
    },
    inputChange() {
      this.inputValue = this.inputValue
        .replace(/[^\d.]/g, '')
        .replace(/\.{2,}/g, '.')
        .replace('.', '$#$')
        .replace(/\./g, '')
        .replace('$#$', '.')
        .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');
      if(this.integer){
        this.inputValue=this.inputValue.replace(/[^\d]/g,'')
      }
      this.$emit('input', this.inputValue);
    },
  },
};
