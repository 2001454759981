
// 组件
import LogisticsDetails from './LogisticsDetails.vue';

export default {
  name: 'LogiInfoTable',
  components: { LogisticsDetails },
  props: {
    // 表格数据
    tableData: {
      type: Array,
      default: () => [],
    },
    // 订单编号
    tid: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      // 表格配置
      tableFiled: [
        {
          filed: 'ship_time',
          text: '发货时间',
          width: '250',
        },
        {
          filed: 'express_company',
          text: '物流公司',
        },
        {
          filed: 'logistics_no',
          text: '快递单号',
          width: '350',
        },
        {
          filed: 'operation',
          text: '操作',
          width: '156',
          isEdit: true,
        },
      ],

      logiDetailsDialog: false, // 物流详情弹窗状态
      selectedLogiNum: '', // 选中的物流单号/记录id
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 点击查看物流
    handleLookLogiDetail(logiNum) {
      this.selectedLogiNum = logiNum;
      this.logiDetailsDialog = true;
    },
    // 关闭弹窗
    closeDialog() {
      this.logiDetailsDialog = false;
      this.selectedLogiNum = '';
    },
  },
  mounted() {},
};
